import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItem,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import React from "react";

function RecommendationsObservationsItem ({index, formData, handleInputChanged}) {
    return (
        <ListItem sx={{display: 'list-item'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField required id={`reparations${index}`} name={`reparations${index}`} defaultValue={formData[`reparations${index}`]} label="Recomandari" fullWidth/>
                </Grid>
                <Grid item xs={4} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="class-code-select-label">Cod Clasificare</InputLabel>
                        <Select required fullWidth name={`reparationsCode${index}`} id={`reparationsCode${index}`} defaultValue={formData[`reparationsCode${index}`] || "C1"} labelId="class-code-select-label" label="Cod Clasificare">
                            <MenuItem value={"C1"}>C1</MenuItem>
                            <MenuItem value={"C2"}>C2</MenuItem>
                            <MenuItem value={"C3"}>C3</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8} md={4} display="flex" alignItems="center">
                    <FormControlLabel name={`reparationsAdditional${index}`} checked={formData[`reparationsAdditional${index}`] === true} value={true} onChange={handleInputChanged} control={<Checkbox />} label="Investigatii suplimentare necesare"/>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default RecommendationsObservationsItem;
