const INSPECTION_STARTED = 'INSPECTION_STARTED'
const INSPECTION_ENDED = 'INSPECTION_ENDED'
const TO_REVIEW = 'TO_REVIEW'
const SUPERVISION_STARTED = 'SUPERVISION_STARTED'
const SUPERVISION_ENDED = 'SUPERVISION_ENDED'
const CHANGES_NEEDED = 'CHANGES_NEEDED'

export const FormStatus = {
    INSPECTION_STARTED,
    INSPECTION_ENDED,
    TO_REVIEW,
    SUPERVISION_STARTED,
    SUPERVISION_ENDED,
    CHANGES_NEEDED
}



export const FormStatusDisplay = {
    [FormStatus.INSPECTION_STARTED]: 'Inspectie inceputa',
    [FormStatus.INSPECTION_ENDED]: 'Inspectie finalizata',
    [FormStatus.TO_REVIEW]: 'Necesita supervizare',
    [FormStatus.SUPERVISION_STARTED]: 'Supervizare inceputa',
    [FormStatus.SUPERVISION_ENDED]: 'Supervizare finalizata',
    [FormStatus.CHANGES_NEEDED]: 'Necesita schimbari'
}
