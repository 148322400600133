import JsCookie from "js-cookie";

export const getAuthorizedRequestHeaders = () => {
    const token = JsCookie.get("jwt");
    if (token) {
        return {
            headers: {"Authorization" : token}
        }
    }
}

export const defaultPageSize = 6;

const devEnv = process.env.NODE_ENV === 'development';
// const devEnv = true;

export const serverUrl = devEnv ? 'http://localhost:8080' : ''
