import {Alert, AlertTitle, Snackbar} from "@mui/material";


const ErrorDialog = ({error, onClose}) => {
    return (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={onClose} anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={onClose} severity="error" sx={{width: '100%'}} variant={"filled"}>
                <AlertTitle>
                    Eroare
                </AlertTitle>
                {error}
            </Alert>
        </Snackbar>
    )
}

export default ErrorDialog