import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import FormLayout from 'layout/FormLayout';
import DekraEEForm from 'pages/DekraEEForm';
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import SecureRoute from './security/SecureRoute';
import SecurityProvider from './security/SecurityProvider';
import Forms from "./pages/Forms";
import UserProvider from "./hooks/user/UserProvider";
import ErrorContainer from "./hooks/error/ErrorContainer";
import {QueryClient, QueryClientProvider} from "react-query";
import AdminReportsPanel from "./pages/AdminReportsPanel";
import PathRedirect from "./components/PathRedirect";
import UserManagementPanel from "./pages/UserManagementPanel";
import {roRO} from "@mui/material/locale";

const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#008143',
            },
            secondary: {
                main: '#006B52',
            },
        },
    },
    roRO
);

const queryClient = new QueryClient()

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ErrorContainer>
                        <SecurityProvider>
                            <UserProvider>
                                <Routes>
                                    <Route path="/login" element={<Login/>}/>
                                    <Route path='/forms' element={<SecureRoute/>}>
                                        <Route path="/forms" element={<FormLayout/>}>
                                            <Route path="/forms/" element={<Forms/>}/>
                                            <Route path="/forms/EE-form" element={<DekraEEForm/>}/>
                                            <Route path="/forms/EE-form/:id" element={<DekraEEForm/>}/>
                                        </Route>
                                    </Route>
                                    <Route path="/admin" element={<SecureRoute/>}>
                                        <Route path="/admin" element={<FormLayout/>}>
                                            <Route path="/admin/" element={<Navigate replace to={'/admin/reports'}/>}/>
                                            <Route path="/admin/reports" element={<AdminReportsPanel/>}/>
                                            <Route path="/admin/users" element={<UserManagementPanel/>}/>
                                        </Route>
                                    </Route>
                                    <Route exact path="/" element={<PathRedirect/>}/>
                                </Routes>
                            </UserProvider>
                        </SecurityProvider>
                    </ErrorContainer>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
