import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import useSecurity from '../hooks/useSecurity'
import RoleBasedSecuredRoute from "./RoleBasedSecuredRoute";

const SecureRoute = () => {
    const {loggedIn} = useSecurity();
    const location = useLocation();

    return (
        loggedIn ?
            <RoleBasedSecuredRoute/>
            :
            <Navigate to="/login" state={{from: location}}/>


    )
}

export default SecureRoute
