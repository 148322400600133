import {useCallback, useState} from 'react'
import ErrorHandlerProvider from './ErrorHandlerProvider'
import ErrorDialog from './ErrorDialog'

const ErrorContainer = (props) => {
    const [error, setError] = useState({});
    const [errorTitle, setErrorTitle] = useState("");

    const callback = useCallback((title, err) => {
        console.error('ERROR RAISED ')
        console.error('Error title: ', title)
        console.error('Error content', JSON.stringify(err))
        setError(err)
        setErrorTitle(title)
    }, []);

    return (
        <ErrorHandlerProvider callback={callback}>
            {props.children}

            {error && (
                <ErrorDialog
                    error={errorTitle}
                    onClose={() => {
                        setError(null);
                        setErrorTitle("");
                    }}
                />
            )}
        </ErrorHandlerProvider>
    )
}

export default ErrorContainer