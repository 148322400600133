import {Skeleton, Typography} from "@mui/material";
import React from "react";

function LoadingSkeletonCards() {
    return (
        <Typography variant="h1">
            <Skeleton variant="rounded" sx={{marginBottom: 2}}/>
            <Skeleton variant="rounded" sx={{marginBottom: 2}}/>
            <Skeleton variant="rounded" sx={{marginBottom: 2}}/>
        </Typography>
    );
}

export default LoadingSkeletonCards;