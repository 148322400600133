import {Box, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import React from "react";
import FormStateChip from "./form/FormStateChip";

function ReportStatusCountCard({status, count, loading}) {
    return (
        <Card sx={{minHeight: 120}}>
            {
                loading ?
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    <CardContent>
                        <Typography variant="h3" component="div">
                            {count}
                        </Typography>
                        <Typography sx={{fontSize: 18}} component="div">
                            <FormStateChip status={status}/>
                        </Typography>
                    </CardContent>
            }
        </Card>
    );
}

export default ReportStatusCountCard;
