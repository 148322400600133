import axios from "./axios-client";
import {getAuthorizedRequestHeaders, serverUrl} from "./apiUtils";

export const getAdminFormList = async () => {
    const {data} = await axios.get(serverUrl + `/api/admin/reports`, getAuthorizedRequestHeaders());
    return data;
}

export const getReportStatusCountStats = async (time) => {
    const {data} = await axios.get(serverUrl + `/api/admin/reports/stats?time=${time}`, getAuthorizedRequestHeaders())
    return data
}

export const getAdminUserList = async () => {
    const {data} = await axios.get(serverUrl + `/api/admin/users`, getAuthorizedRequestHeaders());
    return data;
}

export const createUser = async (data) => {
    return await axios.post(serverUrl + "/api/admin/users/register", data, getAuthorizedRequestHeaders());
}

export const updateUser = async (data) => {
    return await axios.put(serverUrl + "/api/admin/users/update", data, getAuthorizedRequestHeaders());
}
