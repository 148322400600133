import {Box, Button, Container, Grid, InputBase, Paper, Tab, Tabs, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAllCurrentUserForms, getAllFormsOpenForReview, searchForms} from "../api/formApi";
import {useQuery, useQueryClient} from "react-query";
import useUser from "../hooks/user/useUser";
import UserRole from "../utils/UserRole";
import FormCardList from "../components/form/FormCardList";
import useErrorHandler from "../hooks/error/useErrorHandler";
import {Search} from "@mui/icons-material";
import {defaultPageSize} from "../api/apiUtils";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function FormCardListFetch({queryKey, queryFn, errorMessage}) {
    const setError = useErrorHandler();
    const queryClient = useQueryClient()
    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const {isLoading, isError, data, error, isPreviousData} = useQuery({
        queryKey: [queryKey, page],
        queryFn: () => queryFn(page),
        keepPreviousData: true
    });

    useEffect(() => {
        if (!isPreviousData) {
            queryClient.fetchQuery({
                queryKey: [queryKey, page + 1],
                queryFn: () => queryFn(page + 1),
            }).then(response => {
                if (response.length > 0) {
                    setHasNext(true);
                } else {
                    setHasNext(false);
                }
            })
        }
    }, [data, isPreviousData, page, queryClient, queryKey, queryFn])

    if (isError) {
        setError(errorMessage, error);
    }

    return (
        <>
            <FormCardList forms={data} isLoading={isLoading}/>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Button
                    onClick={() => setPage((old) => Math.max(old - 1, 0))}
                    disabled={page === 0}
                    sx={{m: "1"}}
                >
                    Pagina anterioară
                </Button>
                <Button
                    onClick={() => {
                        setPage((old) => (hasNext ? old + 1 : old))
                    }}
                    disabled={isPreviousData || !hasNext}
                    sx={{m: "1"}}
                >
                    Pagina următoare
                </Button>
            </Box>
        </>
    );
}

function CurrentUserForms() {
    return (
        <FormCardListFetch errorMessage={"Rapoartele tale nu au putut sa fie aduse"}
                           queryKey={"currentUserForms"}
                           queryFn={getAllCurrentUserForms}
        />
    );
}

function NeedsReviewForms() {
    return (
        <FormCardListFetch errorMessage={"Rapoartele neverificate nu au putut sa fie aduse"}
                           queryKey={"openForReviewForms"}
                           queryFn={getAllFormsOpenForReview}
        />
    );
}

function SearchFormList({searchResults, loading}) {
    const [searchPage, setSearchPage] = useState(0);
    const [numberOfSearchResults, setNumberOfSearchResults] = useState(0);

    useEffect(() => {
        setSearchPage(0);
        setNumberOfSearchResults(getNumberOfSearchResults());
        // eslint-disable-next-line
    }, [searchResults]);

    function getNumberOfSearchResults() {
        if (!searchResults || searchResults.length === 0) {
            return 0;
        }
        let count = (searchResults.length - 1) * defaultPageSize;
        count += searchResults[searchResults.length - 1].length;
        return count;
    }

    return (
        <>
            {
                !loading &&
                <Typography variant='h5' sx={{mb: 2}}>
                    Rapoarte gasite: {numberOfSearchResults}
                </Typography>
            }
            <FormCardList forms={searchResults[searchPage]} isLoading={loading}/>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Button
                    onClick={() => setSearchPage((old) => Math.max(old - 1, 0))}
                    disabled={searchPage === 0}
                    sx={{m: "1"}}
                >
                    Pagina anterioară
                </Button>
                <Button
                    onClick={() => {
                        setSearchPage((old) => (old + 1))
                    }}
                    disabled={searchPage === searchResults.length - 1}
                    sx={{m: "1"}}
                >
                    Pagina următoare
                </Button>
            </Box>
        </>
    );
}

function Forms() {
    const navigate = useNavigate();
    const setError = useErrorHandler();
    const {userHasRole} = useUser();
    const [tabId, setTabId] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const handleTabChange = (event, value) => {
        setTabId(value);
    }

    const handleSearch = async () => {
        function splitArrayIntoChunks(arr, n) {
            const result = [];
            let i = 0;
            while (i < arr.length) {
                result.push(arr.slice(i, i + n).filter((value, index, self) => self.indexOf(value) === index));
                i += n;
            }
            return result;
        }

        if (searchTerm === "") {
            setSearchResults([]);
            return;
        }
        setSearchLoading(true);
        try {
            const response = await searchForms(searchTerm);
            const splitResponse = splitArrayIntoChunks(response, defaultPageSize);
            setSearchResults(splitResponse);
        } catch (e) {
            setError("Cautarea nu a putut fi efectuata", e);
        }
        setSearchLoading(false);
    }

    const searchBarSize = userHasRole(UserRole.INSPECTOR) ? 6 : 9;
    const buttonBoxSize = userHasRole(UserRole.INSPECTOR) ? 6 : 3;
    return (
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={2} sx={{
                marginBottom: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <Grid item xs={searchBarSize} md={searchBarSize} lg={searchBarSize}>
                    <Paper
                        component="form"
                        sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%"}}
                    >
                        <InputBase
                            sx={{ml: 1, flex: 1, py: "3px"}}
                            placeholder="Cauta rapoarte"
                            inputProps={{'aria-label': 'cauta rapoarte'}}
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                        />

                    </Paper>
                </Grid>
                <Grid item xs={buttonBoxSize} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: userHasRole(UserRole.INSPECTOR) ? 'space-between' : 'flex-end',
                }}>
                    <Button variant={'contained'} size="large" startIcon={<Search/>} onClick={handleSearch}>
                        Cautare
                    </Button>
                    {
                        userHasRole(UserRole.INSPECTOR) &&
                        <Button variant="contained" size="large" onClick={() => navigate("/forms/EE-form")}>
                            Raport nou
                        </Button>

                    }
                </Grid>
            </Grid>
            {
                (searchLoading || searchResults.length > 0) ?
                    <SearchFormList searchResults={searchResults} loading={searchLoading}/>
                    :
                    userHasRole(UserRole.SUPERVISOR) ?
                        <>
                            <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 3}}>
                                <Tabs value={tabId} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Rapoartele mele"/>
                                    <Tab label="Rapoarte neverificate"/>
                                </Tabs>
                            </Box>
                            <TabPanel value={tabId} index={0}>
                                <CurrentUserForms/>
                            </TabPanel>
                            <TabPanel value={tabId} index={1}>
                                <NeedsReviewForms/>
                            </TabPanel>
                        </>
                        :
                        <CurrentUserForms/>
            }
        </Container>
    );
}

export default Forms;
