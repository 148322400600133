import {DataGrid, GridActionsCellItem, GridToolbar, roRO, useGridApiRef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {getAdminFormList, getReportStatusCountStats} from "../api/adminApi";
import {Container} from "@mui/system";
import useErrorHandler from "../hooks/error/useErrorHandler";
import {Box, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import ReportStatusCountCard from "../components/ReportStatusCountCard";
import {FormStatus, FormStatusDisplay} from "../utils/FormStatus";
import FormStateChip from "../components/form/FormStateChip";
import {CloudDownload} from "@mui/icons-material";
import {downloadForm} from "../api/formApi";
import LoadingBackdrop from "../components/LoadingBackdrop";

function AdminReportsPanel() {
    const columns = [
        {
            field: 'formId',
            headerName: 'Numar raport',
            width: 140
        },
        {
            field: 'formType',
            headerName: 'Tip raport',
            width: 100,
        },
        {
            field: 'clientName',
            headerName: 'Client',
            width: 150
        },
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: (params) => [
                <>
                    {
                        params.row.formStatus === FormStatus.SUPERVISION_ENDED &&
                        <GridActionsCellItem
                            icon={<CloudDownload fontSize={"large"}/>}
                            label="Download"
                            onClick={() => handleDownloadPdf(params.id)}
                        />
                    }
                </>

            ]
        },
        {
            field: 'formStatus',
            headerName: 'Status',
            width: 200,
            renderCell: (params) => {
                return (<FormStateChip status={params.row.formStatus} size={1} variant={'outlined'}/>);
            },
            valueGetter: (params) => {
                return FormStatusDisplay[params.value];
            }
        },
        {
            field: 'inspectorName',
            headerName: 'Inspector',
            width: 150
        },
        {
            field: 'supervisorName',
            headerName: 'Supervizor',
            width: 150
        },
        // {
        //     field: 'inspectionStart',
        //     type: 'date',
        //     headerName: 'Data inceperii inspectiei',
        //     width: 200,
        //     valueGetter: (params) => {
        //         return new Date(params.value);
        //     }
        // },
        {
            field: 'inspectionEnd',
            type: 'dateTime',
            headerName: 'Data inspectiei',
            width: 200,
            valueGetter: (params) => {
                if (!!params.value) {
                    return new Date(params.value);
                }
                return null;
            }
        },
        // {
        //     field: 'supervisionStart',
        //     type: 'date',
        //     headerName: 'Data inceperii supervizarii',
        //     width: 200,
        //     valueGetter: (params) => {
        //         return new Date(params.value);
        //     }
        // },
        {
            field: 'supervisionEnd',
            type: 'dateTime',
            headerName: 'Data supervizarii',
            width: 200,
            valueGetter: (params) => {
                if (!!params.value) {
                    return new Date(params.value);
                }
                return null;
            }
        },
    ];

    const handleDownloadPdf = async (formId) => {
        setDownloading(true);
        try {
            const response = await downloadForm(formId);
            const linkSource = `data:application/pdf;base64,${response.data}`;
            const downloadLink = document.createElement("a");
            const fileName = response.headers["content-disposition"];
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        } catch (e) {
            setError("Eroare la descarcarea raportului", e);
        }
        setDownloading(false);
    }


    const [dataRows, setDataRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportStatusCountLoading, setReportStatusCountLoading] = useState(false);
    const [reportStatusCountMap, setReportStatusCountMap] = useState({
        [FormStatus.TO_REVIEW]: 0,
        [FormStatus.SUPERVISION_STARTED]: 0,
        [FormStatus.SUPERVISION_ENDED]: 0,
        [FormStatus.CHANGES_NEEDED]: 0
    });
    const [reportStatusCountTimeFilter, setReportStatusCountTimeFilter] = useState(1);
    const [downloading, setDownloading] = useState(false);
    const setError = useErrorHandler();
    const apiRef = useGridApiRef();

    async function fetchReportStatusCountStats() {
        setReportStatusCountLoading(true);
        try {
            const data = await getReportStatusCountStats(reportStatusCountTimeFilter);
            setReportStatusCountMap(data);
        } catch (e) {
            setError('Datele despre numarul de rapoarte nu au putut fi aduse', e);
        }
        setReportStatusCountLoading(false);
    }

    useEffect(() => {
        async function fetchAdminFormList() {
            setLoading(true);
            try {
                const data = await getAdminFormList();
                setDataRows(data);
            } catch (e) {
                setError('Datele nu au putut sa fie aduse', e);
            }
            setLoading(false);
        }

        fetchAdminFormList();
        fetchReportStatusCountStats();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchReportStatusCountStats(reportStatusCountTimeFilter);
        // eslint-disable-next-line
    }, [reportStatusCountTimeFilter]);

    function handleReportStatusCountTimeFilter(event) {
        const time = event.target.value;
        setReportStatusCountTimeFilter(time);
    }

    return (
        <Container sx={{height: '90%', pb: 2}} maxWidth={'100%'}>
            <LoadingBackdrop open={downloading}/>
            <Typography variant="h5" sx={{mt: 3}}>
                Statistici rapoarte
            </Typography>
            <Box sx={{mt: 1, mb: 2}}>
                <InputLabel id="type-select-label">Filtru zile</InputLabel>
                <Select value={reportStatusCountTimeFilter} onChange={handleReportStatusCountTimeFilter}
                        labelId="type-select-label" sx={{minWidth: 120}}>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                </Select>
            </Box>
            <Grid container spacing={2} sx={{mb: 4}}>
                <Grid item xs={6} md={3} lg={3}>
                    <ReportStatusCountCard count={reportStatusCountMap[FormStatus.TO_REVIEW]}
                                           loading={reportStatusCountLoading}
                                           status={FormStatus.TO_REVIEW}/>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <ReportStatusCountCard count={reportStatusCountMap[FormStatus.SUPERVISION_STARTED]}
                                           loading={reportStatusCountLoading}
                                           status={FormStatus.SUPERVISION_STARTED}/>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <ReportStatusCountCard count={reportStatusCountMap[FormStatus.SUPERVISION_ENDED]}
                                           loading={reportStatusCountLoading}
                                           status={FormStatus.SUPERVISION_ENDED}/>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <ReportStatusCountCard count={reportStatusCountMap[FormStatus.CHANGES_NEEDED]}
                                           loading={reportStatusCountLoading}
                                           status={FormStatus.CHANGES_NEEDED}/>
                </Grid>
            </Grid>
            <Typography variant="h5" sx={{mb: 3}}>
                Administrare rapoarte
            </Typography>
            <DataGrid
                sx={{height: '69%'}}
                apiRef={apiRef}
                rows={dataRows}
                columns={columns}
                loading={loading}
                getRowId={row => row.formId}
                localeText={{
                    ...roRO.components.MuiDataGrid.defaultProps.localeText,
                    columnMenuManageColumns: 'Gestionare coloane',
                }}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{toolbar: GridToolbar}}
            />
        </Container>
    )
}

export default AdminReportsPanel;
