import {BarChart, ChevronLeft, ElectricBolt, Group, Menu, Person} from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useSecurity from "../hooks/useSecurity";
import useUser from "../hooks/user/useUser";
import UserRole from "../utils/UserRole";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: 0,
                [theme.breakpoints.up('sm')]: {
                    width: 0,
                },
            }),
        },
    }),
);

const FormLayout = () => {

    const [open, setOpen] = useState(false);
    const [pageTitle, setPageTitle] = useState("Rapoarte");
    const navigate = useNavigate();
    const {logout} = useSecurity();
    const {getUserEmail, getUserRoles, userHasAnyRole} = useUser();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/forms/EE-form') {
            setPageTitle("Raport EE nou");
        } else if (location.pathname.startsWith('/forms/EE-form/DKR')) {
            const formId = location.pathname.replace('/forms/EE-form/', '');
            setPageTitle(`Raport ${formId}`);
        } else if (location.pathname === '/admin/reports') {
            setPageTitle('Vizualizare rapoarte');
        }else if (location.pathname === '/admin/users') {
            setPageTitle('Administrare utilizatori');
        } else {
            setPageTitle("Rapoarte EE");
        }
    }, [location]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="absolute" open={open}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <Menu/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1}}
                    >
                        {pageTitle}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <Button variant="text" color="inherit" onClick={logout}>Logout</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: [1],
                    }}
                >
                    <img alt={'logo'} width={125} src={"dekralogo-horizontal.png"}/>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeft/>
                    </IconButton>
                </Toolbar>
                <Divider/>
                <List>
                    {
                        userHasAnyRole([UserRole.SUPERVISOR, UserRole.INSPECTOR, UserRole.SYSTEM_ADMIN]) &&
                        <ListItemButton onClick={() => navigate("/forms")}>
                            <ListItemIcon>
                                <ElectricBolt/>
                            </ListItemIcon>
                            <ListItemText primary="Rapoarte EE"/>
                        </ListItemButton>
                    }
                    {
                        userHasAnyRole([UserRole.SYSTEM_ADMIN, UserRole.ADMIN]) &&
                        <>
                            <ListItemButton onClick={() => navigate("/admin/reports")}>
                                <ListItemIcon>
                                    <BarChart/>
                                </ListItemIcon>
                                <ListItemText primary="Vizualizare rapoarte"/>
                            </ListItemButton>
                            <ListItemButton onClick={() => navigate("/admin/users")}>
                                <ListItemIcon>
                                    <Group/>
                                </ListItemIcon>
                                <ListItemText primary="Administrare utilizatori"/>
                            </ListItemButton>
                        </>
                    }
                    <Divider sx={{my: 1}}/>
                </List>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                        position: "absolute",
                        bottom: "10px"
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <Person color={"primary"} fontSize={"large"}/>
                            </ListItemIcon>
                            <ListItemText primary={getUserEmail()}
                                          secondary={getUserRoles()?.reduce((formattedRoles, role) => formattedRoles + " " + (role !== 'USER' ? role : ''), "")}
                                          sx={{whiteSpace: "normal"}}/>
                        </ListItem>
                    </List>
                </Toolbar>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Outlet/>
            </Box>
        </Box>
    )
};

export default FormLayout;
