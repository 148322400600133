import React, {useEffect} from "react";
import {Card, CardActionArea, CardContent, Grid, Typography} from "@mui/material";
import FormStateChip from "./FormStateChip";
import {useNavigate} from "react-router-dom";
import {CloudDownload} from "@mui/icons-material";
import {FormStatus} from "../../utils/FormStatus";
import FormActionsDialog from "./FormActionsDialog";
import useUser from "../../hooks/user/useUser";
import UserRole from "../../utils/UserRole";

function FormCard({formId, date, status, inspectorName, clientName, formType}) {
    const navigate = useNavigate();
    const {userHasAnyRole, userHasRole} = useUser();
    const [openActionDialog, setOpenActionDialog] = React.useState(false);
    const [canDownloadForm, setCanDownloadForm] = React.useState(false);

    useEffect(() => {
        if (status === FormStatus.SUPERVISION_ENDED && userHasAnyRole([UserRole.SUPERVISOR, UserRole.SYSTEM_ADMIN])) {
            setCanDownloadForm(true);
        }
    }, [canDownloadForm, status, userHasAnyRole])

    function handleClick() {
        if (canDownloadForm) {
            handleOpenDialog()
        } else {
            handleOpenForm();
        }
    }

    const handleOpenForm = () => {
        navigate(`/forms/EE-form/${formId}`);
    }

    const handleCloseDialog = () => {
        setOpenActionDialog(false);
    }

    const handleOpenDialog = () => {
        setOpenActionDialog(true);
    }

    function isFormCardDisabled() {
        return userHasRole(UserRole.INSPECTOR) && (status === FormStatus.SUPERVISION_STARTED || status === FormStatus.SUPERVISION_ENDED);
    }

    return (
        <>
            <FormActionsDialog openDialog={openActionDialog} handleCloseDialog={handleCloseDialog} formId={formId}
                               handleOpenForm={handleOpenForm}/>
            <Card sx={{minWidth: 275, marginBottom: 2}}>
                <CardActionArea onClick={handleClick} sx={{height: '100%'}} disabled={isFormCardDisabled()}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography sx={{fontSize: 16}} color="text.secondary" gutterBottom>
                                    {date}
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {`${formType} - ${formId}`}
                                </Typography>
                                <Typography sx={{fontSize: 18}} component="div">
                                    {`Inspector: ${inspectorName}`}
                                </Typography>
                                <Typography sx={{fontSize: 18}} component="div">
                                    {`Client: ${clientName}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{display: "flex", alignItems: "center", justifyContent: "right"}}>
                                {
                                    canDownloadForm &&
                                    <CloudDownload fontSize={'large'} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: '100%',
                                        justifyContent: 'right',
                                        mr: 2
                                    }} color={'secondary'}/>
                                }
                                <FormStateChip status={status}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
}

export default FormCard;
