import React, {useEffect, useMemo, useState} from 'react'
import UserContext from "./UserContext";
import useSecurity from "../useSecurity";
import {getCurrentUser} from "../../api/userApi";
import useErrorHandler from "../error/useErrorHandler";
import {useNavigate} from "react-router-dom";

const UserProvider = (props) => {
    const {loggedIn, logout} = useSecurity();
    const [user, setUser] = useState();
    const setError = useErrorHandler();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn) {
            setUser(undefined);
            getCurrentUser()
                .then((response) => setUser(response.data))
                .catch((err) => {
                    setError("Detaliile userului nu au putut fi incarcate", err);
                    logout();
                });
        } else {
            navigate("/login");
        }
        // eslint-disable-next-line
    }, [loggedIn]);

    const userContextValue = useMemo(() => ({
        getUserEmail: () => {
            return user?.email;
        },
        getUserRoles: () => {
            return user?.roles?.map(role => role.name);
        },
        userHasRole: (role) => {
            return user?.roles?.map(role => role.name).includes(role);
        },
        userHasAnyRole: (roles) => {
            return roles.map(role => user?.roles?.map(role => role.name).includes(role)).includes(true);
        },
        user
    }), [user]);

    return (
        <UserContext.Provider value={userContextValue}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider
