import React, {useMemo, useState} from 'react'
import {userLogin, userLogout} from '../api/userApi'
import SecurityContext from './SecurityContext'
import JsCookie from "js-cookie";
import useErrorHandler from "../hooks/error/useErrorHandler";
import {useNavigate} from "react-router-dom";

const SecurityProvider = (props) => {
    const [loggedIn, setLoggedIn] = useState(!!JsCookie.get("jwt"));
    const navigate = useNavigate();
    const setError = useErrorHandler();

    const securityContextValue = useMemo(() => ({
        login: async (username, password) => {
            try {
                await userLogin(username, password)
                setLoggedIn(true)
            }
            catch (err) {
                setError("Userul nu a fost gasit in sistem", err);
            }

        },
        logout: () => {
            userLogout();
            setLoggedIn(false);
            navigate("/login", {replace: true})
        },
        loggedIn,
    }), [loggedIn, setError, navigate]);

    return (
        <SecurityContext.Provider value={securityContextValue}>
            {props.children}
        </SecurityContext.Provider>
    )
}

export default SecurityProvider