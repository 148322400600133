// api-client.js
import JsCookie from "js-cookie";
import axios from 'axios';

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 403) {
        JsCookie.remove("jwt");
        window.location.reload();
    }
  });

export default axios;