import {FormControl, Grid, InputLabel, ListItem, MenuItem, Select, TextField, Typography} from "@mui/material";
import React from "react";

function AdditionalChecksItem({text, suffix, formData}) {
    return (
        <ListItem sx={{display: "list-item"}}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6} sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography component="p">{text}</Typography>
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel id="result-select-label">Rezultat</InputLabel>
                        <Select
                            required
                            defaultValue={formData[`resultAddChecks${suffix}`]}
                            id={`resultAddChecks${suffix}`}
                            name={`resultAddChecks${suffix}`}
                            labelId="result-select-label"
                            label="Rezultat">
                            <MenuItem value={"✓"}>✓</MenuItem>
                            <MenuItem value={"N/A"}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <TextField id={`obs${suffix}`} label="Observații" defaultValue={formData[`obs${suffix}`]} name={`obs${suffix}`} fullWidth/>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default AdditionalChecksItem;
