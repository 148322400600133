import {formatDateFromMoment} from "../../utils/DateUtils";
import FormCard from "./FormCard";
import React from "react";
import LoadingSkeletonCards from "../LoadingSkeletonCards";

function FormCardList({forms, isLoading}) {
    return (
        isLoading ?
            <LoadingSkeletonCards/> :
            forms?.map(form => {
                const formId = form.formId;
                const date = formatDateFromMoment(form.date);
                const inspectorName = form.inspectorName;
                const clientName = form.clientName;
                const formType = form.formType;
                return <FormCard status={form.status}
                                 formId={formId}
                                 date={date}
                                 key={formId}
                                 inspectorName={inspectorName}
                                 clientName={clientName}
                                 formType={formType}/>
            })
    )
}

export default FormCardList;
