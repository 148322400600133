import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import React from "react";
import useUser from "../../hooks/user/useUser";
import UserRole from "../../utils/UserRole";
import {FormStatus} from "../../utils/FormStatus";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FormConfirmationDialog({openDialog, handleCancel, handleSubmitForm, handleChangesNeeded, status}) {
    const {userHasRole} = useUser();

    const getDialogText = () => {
        if (userHasRole(UserRole.SUPERVISOR)) {
            return 'Finalizarea supervizarii cu sucess va schimba statusul raportului in "Finalizat".' +
            'Daca sunt prezente erori sau schimbari ce trebuie realizate de catre inspector apasati butonul "Necesita modificari" si discutati cu inspectorul responsabil in legatura cu schimbarile ce trebuie realizate.' +
            'Daca doriti sa mai realizati modificari la formular, apasati butonul "Anulare".'
        }
        return 'Finalizarea formularului va trimite raportul catre un supervizor pentru a-l putea verifica si semna. Daca doriti sa mai realizati modificari la formular, apasati butonul "Anulare".'
    }

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCancel}
            aria-describedby="cancel-dialog"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle>{"Finalizare formular"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="cancel-dialog">
                    {getDialogText()}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between", m: 2}}>
                <Button onClick={handleCancel}>Anulare</Button>
                <Box  sx={{justifyContent: "space-between"}}>
                    {
                        userHasRole(UserRole.SUPERVISOR) && status !== FormStatus.SUPERVISION_ENDED &&
                        <Button onClick={handleChangesNeeded} variant={"contained"} color={'warning'} sx={{m: 2}}>Necesita modificari</Button>
                    }
                    <Button onClick={handleSubmitForm} variant={"contained"} sx={{ml: 2}}>Finalizeaza raport</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default FormConfirmationDialog;
