import React, {useRef, useState} from "react";
import SignaturePad from "react-signature-pad-wrapper";
import {Box, Button, FormControl, InputBase, Modal} from "@mui/material";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

function Signature({initialSignature, handleSave}) {
    const [open, setOpen] = useState(false);
    const [signature, setSignature] = useState(toBase64(initialSignature));
    const [displayImage, setDisplayImage] = useState(!!initialSignature);
    const canvas = useRef();

    const clear = (ref) => {
        ref.current?.clear();
    };

    function getBase64(dataURL) {
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    function toBase64(dataURL) {
        return `data:image/png;base64,${dataURL}`;
    }

    const save = (ref) => {
        if (!ref.current?.isEmpty()) {
            setSignature(ref.current?.toDataURL());
            setDisplayImage(true);
            setOpen(false);
            handleSave(getBase64(ref.current?.toDataURL()));
        }
    };

    function handleClose() {
        return () => setOpen(false);
    }

    function handleOpen() {
        return () => setOpen(true);
    }

    return (
        <>
            <Modal open={open} onClose={handleClose()}>
                <Box sx={modalStyle}>
                    <Box sx={{border: "2px solid lightgray"}}>
                        <SignaturePad ref={canvas} options={{backgroundColor: "rgb(255, 255, 255)"}}/>
                    </Box>
                    <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
                        <Button variant="outlined" onClick={() => clear(canvas)} sx={{m: 3}}>
                            Sterge
                        </Button>
                        <Button variant="contained" onClick={() => save(canvas)} sx={{m: 3}}>
                            Salveaza
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <FormControl
                required
                onClick={handleOpen()}
                sx={{
                    width: 231,
                    height: 100,
                    border: "1px solid lightgrey",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {
                    displayImage ?
                        <img width={'100%'} height={'100%'} src={signature} alt={""}/>
                        :
                        <InputBase
                            required
                            placeholder="Semneaza documentul"
                            sx={{
                                caretColor: 'transparent',
                                '& .MuiInputBase-input': {
                                    cursor: 'default'
                                }
                            }}
                        />
                }
            </FormControl>
        </>

    );
}

export default Signature;
