import axios from "./axios-client"
import JsCookie from "js-cookie";
import {getAuthorizedRequestHeaders, serverUrl} from "./apiUtils";


export const userLogin = async (email, password) => {
    const response = await axios.post(serverUrl + "/api/auth/login", {email, password})
    const token = response.data;
    JsCookie.set("jwt", "Bearer " + token, {expires: 1});
}

export const getCurrentUser = async () => {
    return await axios.get(serverUrl + "/api/auth/user", getAuthorizedRequestHeaders());
}

export const userLogout = () => {
    JsCookie.remove("jwt");
}
