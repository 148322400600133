import React from 'react'

const initialUserContext = {
    user: {},
    getUserEmail: () => "",
    getUserRoles: () => [],
    userHasRole: (role) => false,
    userHasAnyRole: (roles) => false,
}
const UserContext = React.createContext(initialUserContext)

export default UserContext;