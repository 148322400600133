import {LockOpenOutlined} from "@mui/icons-material";
import {Avatar, Box, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useSecurity from "../hooks/useSecurity";

function Login() {
    const {loggedIn, login} = useSecurity();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const from = location.state?.from || "/";

    useEffect(() => {
        if (loggedIn) {
            navigate(from, {replace: true});
        }
    }, [loggedIn, navigate, from]);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        await login(email, password);
        setLoading(false);
    }

    return (
        <Grid container component="main" sx={{maxHeight: '100vh'}}>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={12} square
                  sx={{zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box
                    sx={{
                        my: 'auto',
                        mx: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h2" align="center" sx={{fontWeight: '500'}}>
                        DEKRA
                    </Typography>
                    <Typography component="h1" variant="h3" align="center" sx={{fontWeight: '400'}}>
                        Business Operations
                    </Typography>
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOpenOutlined/>
                    </Avatar>
                    <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{mt: 3, mb: 2}}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(loginbg.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    maxHeight: '100vh'
                }}
            >
                <Box sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src="dekralogo.svg" alt="Company logo"
                         style={{width: '25%', opacity: 1, position: 'relative', zIndex: 2}}/>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: (t) => t.palette.primary.main,
                            opacity: 0.5,
                            zIndex: 1,
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default Login;
