import {Button, Grid, List, ListItem, TextField, Typography} from "@mui/material";
import RecommendationsObservationsItem from "./RecommendationsObservationsItem";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import React, {useEffect} from "react";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {currentDate} from "../../utils/DateUtils";

function RecommendationsObservationsSection({formData, setFormData, handleInputChanged, recommendationsObservations, handleAddRow, handleRemoveRow}) {
    const [additionalRecommendedInspectionDate, setAdditionalRecommendedInspectionDate] = React.useState(formData["additionalRecommendedInspectionDate"] || currentDate());

    useEffect(() => {
        setFormData({
            ...formData,
            additionalRecommendedInspectionDate
        })
        // eslint-disable-next-line
    }, [additionalRecommendedInspectionDate])

    return (
        <>
            <Grid container spacing={2} sx={{mb: 1}}>
                <Grid item xs={11} md={11} lg={11}>
                    <List sx={{listStyle: 'numbered', p: 2}}>
                        {recommendationsObservations.map((row, index) => (
                            <RecommendationsObservationsItem index={index} key={index} formData={formData}
                                                             handleInputChanged={handleInputChanged}/>
                        ))}
                    </List>
                    <Typography component="small" sx={{fontWeight: 200}}>
                        *Fiecărei observații menționate mai sus, după caz, i-a fost alocat unul din următoarele coduri
                        pentru a semnala
                        persoanelor responsabile cu instalația gradul de urgență al măsurilor de remediere.
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Button variant={"contained"} sx={{fontSize: 24}}
                            disabled={recommendationsObservations.length === 11} onClick={handleAddRow}>+</Button>
                    <Button variant={"outlined"} color={"inherit"} disabled={recommendationsObservations.length === 1}
                            sx={{fontSize: 24, mt: 2}} onClick={handleRemoveRow}>-</Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                    <List sx={{listStyle: 'disc', pl: 2}}>
                        <ListItem sx={{display: 'list-item', pl: 1}}>
                            Cod C1 - Prezintă pericol. Risc de vătămare, se impun măsuri de remediere imediate.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', pl: 1}}>
                            Cod C2 - Pericol potenţial. Se impun măsuri de remediere urgente.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', pl: 1}}>
                            Cod C3 - Îmbunătăţire recomandată.
                        </ListItem>
                    </List>
                    <Typography component="p">
                        Când evaluarea per ansamblu a instalaţiei în ceea ce priveşte aptitudinea de a fi utilizată în
                        continuare descrisă mai sus este declarată NESATISFĂCĂTOR, eu/noi
                        recomand/recomandăm ca orice observaţie clasificată ”Prezintă pericol” (Cod C1) sau
                        ”Pericol potenţial” (Cod C2) să fie interpretată ca o măsură de urgenţă.
                    </Typography>
                    <Typography component="p" sx={{mt: 2}}>
                        Pentru observaţiile identificate ca “Necesită investigaţie suplimentară” se recomandă o
                        investigaţie imediată.
                    </Typography>
                    <Typography component="p" sx={{mt: 2}}>
                        Se recomandă să se acorde o atenţie deosebită observaţiilor clasificate ca “Îmbunătăţire
                        recomandată” (Cod C3).
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <TextField required id="immediateReparations" defaultValue={formData["immediateReparations"]}
                               name="immediateReparations" label="Masuri de remediere imediat necesare pentru"
                               sx={{mt: 2, mb: 2}} fullWidth/>
                    <TextField required id="urgentReparations" defaultValue={formData["urgentReparations"]}
                               name="urgentReparations" label="Masuri de remediere urgente necesare pentru"
                               sx={{mt: 2, mb: 2}} fullWidth/>
                    <TextField required id="furtherReparations" defaultValue={formData["furtherReparations"]}
                               name="furtherReparations" label="Sunt necesare investigatii ulterioare pentru"
                               sx={{mt: 2, mb: 2}} fullWidth/>
                    <TextField required id="recommendedReparations" defaultValue={formData["recommendedReparations"]}
                               name="recommendedReparations" label="Se recomanda masuri de imbunătăţire pentru"
                               sx={{mt: 2, mb: 2}} fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                    <Typography component="p" sx={{mt: 2}}>
                        În funcţie de măsurile de remediere necesare luate, vă recomand/recomandăm ca instalaţia să fie
                        supusă la o inspecţie şi încercări suplimentare la data:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Data"
                            value={additionalRecommendedInspectionDate}
                            onChange={(newValue) => {
                                setAdditionalRecommendedInspectionDate(newValue);
                            }}
                            id="additionalRecommendedInspectionDate"
                            name="additionalRecommendedInspectionDate"
                            sx={{mt: 2, mb: 2}}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </>
    )
}

export default RecommendationsObservationsSection;
