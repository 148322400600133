import useUser from "../hooks/user/useUser";
import UserRole from "../utils/UserRole";
import {Navigate, Outlet, useLocation} from "react-router-dom";

function RoleBasedSecuredRoute() {
    const {userHasAnyRole, user} = useUser();
    const location = useLocation();

    function userHasAccessToRoute() {
        const path = location.pathname;
        function isFormsRoute() {
            return path.startsWith('/forms');
        }

        function isAdminRoute() {
            return path.startsWith('/admin');
        }


        if (isAdminRoute() && userHasAnyRole([UserRole.ADMIN, UserRole.SYSTEM_ADMIN])) {
            return true;
        }
        return isFormsRoute() && userHasAnyRole([UserRole.INSPECTOR, UserRole.SUPERVISOR, UserRole.SYSTEM_ADMIN]);
    }

    return(
        <>
            {
                user && (userHasAccessToRoute() ? <Outlet/> : <Navigate replace to="/" />)
            }
        </>
    )
}

export default RoleBasedSecuredRoute;
