import {Chip} from "@mui/material";
import {getFormattedStatus} from "../../utils/FormStatusUtils";

function FormStateChip(props) {
    const { size = 1.15, ...restProps } = props;
    const { statusLabel, statusColor } = getFormattedStatus(props.status);
    return (
        <Chip
            label={statusLabel}
            color={statusColor}
            sx={{
                fontSize: `${size * 0.8125}rem`,
                height: `${size * 32}px`,
                borderRadius: '9999px'
            }}
            {...restProps}
        />
    );
}


export default FormStateChip;
