import React from "react";
import {FormControl, Grid, InputLabel, ListItem, MenuItem, Select, TextField, Typography} from "@mui/material";

function InspectionListItem({text, prefix, display, itemNumber, formData}) {
    const [isLim, setIsLim] = React.useState(false);

    return (
        <ListItem sx={{display: display}}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={8} lg={8} sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography component="p">{text}</Typography>
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <FormControl fullWidth required>
                        <InputLabel id="result-select-label">Rezultat</InputLabel>
                        <Select required defaultValue={formData[`result${prefix}item${itemNumber}`]}
                                id={`result${prefix}item${itemNumber}`} name={`result${prefix}item${itemNumber}`}
                                labelId="result-select-label" label="Rezultat"
                                onChange={(e) => {
                                    if(e.target.value === 'LIM') {
                                        setIsLim(true);
                                    } else {
                                        setIsLim(false);
                                    }
                                }
                            }
                        >
                            <MenuItem value={"✓"}>✓</MenuItem>
                            <MenuItem value={"LIM"}>LIM</MenuItem>
                            <MenuItem value={"N/A"}>N/A</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <TextField required={isLim} defaultValue={formData[`placement${prefix}item${itemNumber}`]}
                               id={`placement${prefix}item${itemNumber}`} label="Amplasare"
                               name={`placement${prefix}item${itemNumber}`} fullWidth/>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default InspectionListItem;
