import {Backdrop, CircularProgress} from "@mui/material";

function LoadingBackdrop({open = true}) {
    return (
        <Backdrop
            sx={{ backgroundColor: 'rgba(255,255,255,0.6)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="primary" size={75} />
        </Backdrop>
    );
}

export default LoadingBackdrop;
