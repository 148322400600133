import {DataGrid, GridToolbar, roRO, useGridApiRef} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {createUser, getAdminUserList, updateUser} from "../api/adminApi";
import {Container} from "@mui/system";
import useErrorHandler from "../hooks/error/useErrorHandler";
import UserEditDialog from "../components/UserManagementPanel/UserEditDialog";
import {Button} from "@mui/material";

function AdminUserPanel() {
    const columns = [
        {
            field: 'firstName',
            headerName: 'Prenume',
            width: 120
        },
        {
            field: 'lastName',
            headerName: 'Nume',
            width: 120
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250
        },
        {
            field: 'roles',
            headerName: 'Roluri',
            width: 350,
            renderCell: (params) => (
                <p>
                    {
                        params.value.map((role, index) => (
                            <span key={index}>{role.name}{index === (params.value.length - 1) ? '' : ', '}</span>
                        ))
                    }
                </p>
            ),
        },
        {
            field: 'active',
            type: 'boolean',
            headerName: 'Activ',
            width: 50
        },
    ];

    const [dataRows, setDataRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openUserEditDialog, setOpenUserEditDialog] = useState(false);
    const [createUserMode, setCreateUserMode] = useState(false);
    const setError = useErrorHandler();
    const apiRef = useGridApiRef();

    useEffect(() => {
        fetchAdminUserList();
        // eslint-disable-next-line
    }, [setError]);

    async function fetchAdminUserList() {
        setLoading(true);
        try {
            const data = await getAdminUserList();

            data.forEach((user) => {
                user.roles = user.roles.filter((role) => {return role.name !== 'USER'});
            });

            setDataRows(data);
        }
        catch (e) {
            setError('Datele nu au putut sa fie aduse', e);
        }
        setLoading(false);
    }

    const handleRowClick = (params) => {
        setSelectedUser(params.row);
        setOpenUserEditDialog(true);
    };

    const handleCreateUser = () => {
        setCreateUserMode(true);
        setOpenUserEditDialog(true);
    }

    const handleCancel = () => {
        setOpenUserEditDialog(false);
        setCreateUserMode(false);
        setSelectedUser(null);
    }

    const handleSubmit = async (data) => {
        try {
            createUserMode ?
                await createUser(data)
                :
                await updateUser(data)
        } catch (e) {
            setError('Utilizatorul nu a putut fi salvat.', e);
        }
        await fetchAdminUserList();
    }

    return (
        <Container sx={{ height: 'calc(100% - 117px)', py: 2}} maxWidth={'100%'}>
            <UserEditDialog openDialog={openUserEditDialog} handleCancel={handleCancel} handleSubmit={handleSubmit} user={selectedUser} createUserMode={createUserMode}/>
            <Button color={"primary"} variant={"contained"} onClick={handleCreateUser} sx={{mb:2}}>Adauga utilizator</Button>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-row' : {
                        cursor: "pointer",
                    }
                }}
                apiRef={apiRef}
                rows={dataRows}
                columns={columns}
                loading={loading}
                getRowId={row => row.id}
                localeText={{
                    ...roRO.components.MuiDataGrid.defaultProps.localeText,
                    columnMenuManageColumns: 'Gestionare coloane',
                }}
                disableRowSelectionOnClick
                slots={{toolbar: GridToolbar}}
                onRowClick={handleRowClick}
            />
        </Container>
    )
}

export default AdminUserPanel;
