import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup, Grid,
    Slide,
    TextField
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UserEditDialog({openDialog, handleCancel, handleSubmit, user, createUserMode}) {
    useEffect(() => {
        setFirstName(user?.firstName || '');
        setLastName(user?.lastName || '');
        setEmail(user?.email || '');
        setActive(!!user?.active && true)
        setIsInspector(!!user?.roles?.map(role => role.name).includes("INSPECTOR"));
        setIsSupervisor(!!user?.roles?.map(role => role.name).includes("SUPERVISOR"));
        setIsAdmin(!!user?.roles?.map(role => role.name).includes("ADMIN"));
        setPass('');
        setConfirmPass('');
        setShowResetPassword(false);
        setPasswordMatchText('');
        setPassError(false);
        setEmailError(false);
        setEmailErrorText('');
    },[user]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [active, setActive] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [pass, setPass] = useState(undefined);
    const [confirmPass, setConfirmPass] = useState(undefined);

    const [isInspector, setIsInspector] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const [showResetPassword, setShowResetPassword] = useState(false);
    const [passwordMatchText, setPasswordMatchText] = useState('');
    const [passError, setPassError] = useState(false);

    const handleShowResetPassword = () => {
        setShowResetPassword(!showResetPassword);
    }

    const handleInputChanged = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        switch (name) {
            case 'isInspector':
                setIsInspector(value);
                if(value) setIsSupervisor(!value);
                break;
            case 'isSupervisor':
                setIsSupervisor(value);
                if(value) setIsInspector(!value);
                break;
            case 'isAdmin':
                setIsAdmin(value);
                break;
            case 'active':
                setActive(value);
                break;
            default:
                return;
        }
    }

    const validateUserData = () => {
        if (createUserMode && email === '') {
            setEmailError(true);
            setEmailErrorText('Emailul este obligatoriu.');
            return false;
        }

        if (showResetPassword || createUserMode) {
            if (pass === '') {
                setPassError(true);
                setPasswordMatchText("Parola este obligatorie.");
                return false;
            }

            if (pass !== confirmPass) {
                setPassError(true);
                setPasswordMatchText("Parolele nu coincid.");
                return false;
            }
        }

        return true;
    }

    const updateOrCreateUser = () => {
        const userObj = {
            'id' : createUserMode ? null : user.id,
            'active' : createUserMode ? true : active,
            'email' : email,
            'firstName' : firstName,
            'lastName' : lastName,
            'roles' : [
                {
                    'name' : 'USER'
                }
            ]
        };

        if (validateUserData()) {
            if (showResetPassword || createUserMode) userObj.password = pass;
        } else {
            return;
        }

        if(isInspector) userObj.roles.push({'name' : 'INSPECTOR'})
        if(isSupervisor) userObj.roles.push({'name' : 'SUPERVISOR'})
        if(isAdmin) userObj.roles.push({'name' : 'ADMIN'})

        handleSubmit(userObj);
        onCancel()
    }

    const onCancel = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setActive(false)
        setIsInspector(false);
        setIsSupervisor(false);
        setIsAdmin(false);
        setPass('');
        setConfirmPass('');
        setShowResetPassword(false);
        setPasswordMatchText('');
        setPassError(false);
        setEmailError(false);
        setEmailErrorText('');
        handleCancel();
    }

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={onCancel}
            aria-describedby="user-edit-dialog"
            fullWidth
            maxWidth={"md"}
        >
            {
                createUserMode ?
                    <DialogTitle>{`Adauga utilizator`}</DialogTitle>
                    :
                    <DialogTitle>{`Editare utilizator - ${firstName} ${lastName}`}</DialogTitle>
            }
            <DialogContent>
                <Grid container spacing={2} my={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Prenume"
                            name="firstName"
                            fullWidth
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Nume"
                            name="lastName"
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <TextField
                    label="Email"
                    name="email"
                    type={"email"}
                    fullWidth
                    value={email}
                    required={createUserMode}
                    disabled={!createUserMode}
                    error={emailError}
                    helperText={emailErrorText}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{mb: 2}}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DialogContentText>
                            Roluri (un utilizator nu poate fi si inspector si supervizor):
                        </DialogContentText>
                        <FormGroup row sx={{mb: 2}}>
                            <FormControlLabel
                                name="isInspector"
                                control={<Checkbox
                                    checked={isInspector}
                                    onChange={handleInputChanged}
                                />}
                                label="Inspector"
                            />
                            <FormControlLabel
                                name="isSupervisor"
                                control={<Checkbox
                                    checked={isSupervisor}
                                    onChange={handleInputChanged}
                                />}
                                label="Supervisor"
                            />
                            <FormControlLabel
                                name="isAdmin"
                                control={<Checkbox
                                    checked={isAdmin}
                                    onChange={handleInputChanged}
                                />}
                                label="Admin"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            !createUserMode &&
                            <>
                                <DialogContentText>
                                    Utilizator activ:
                                </DialogContentText>
                                <FormControlLabel
                                    name="active"
                                    control={<Checkbox
                                        checked={active}
                                        onChange={handleInputChanged}
                                    />}
                                    label="Activ"
                                />
                            </>
                        }
                    </Grid>
                </Grid>
                {
                    !createUserMode &&
                    <Button color={"inherit"} variant={"outlined"} onClick={handleShowResetPassword}>Resetare parola</Button>
                }
                {
                    (showResetPassword || createUserMode) &&
                    <Grid container spacing={2} mt={0}>
                        <Grid item xs={6}>
                            <TextField
                                type={"password"}
                                label={createUserMode ? `Parola` : `Parola noua`}
                                name="newPassword"
                                fullWidth
                                required
                                value={pass}
                                error={passError}
                                onChange={(e) => {
                                    setPass(e.target.value);
                                    setPasswordMatchText('');
                                    setPassError(false);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type={"password"}
                                label="Confirma parola"
                                name="confirmPassword"
                                fullWidth
                                required
                                value={confirmPass}
                                error={passError}
                                helperText={passwordMatchText}
                                onChange={(e) => {
                                    setConfirmPass(e.target.value);
                                    setPasswordMatchText('');
                                    setPassError(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between", m: 2}}>
                <Button color={"primary"} variant={"contained"} onClick={onCancel}>Anulare</Button>
                <Button color={"primary"} variant={"contained"} onClick={updateOrCreateUser}>{createUserMode ? `Adauga utilizator` : `Actualizeaza utilizator`}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserEditDialog;
