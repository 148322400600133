import {
    Box,
    Button, Checkbox,
    FormControl,
    FormControlLabel, FormGroup,
    FormLabel,
    Grid, InputLabel, List, ListItem,
    MenuItem, Paper,
    Radio,
    RadioGroup,
    Select, Step, StepButton, Stepper,
    TextField, Tooltip,
    Typography, ClickAwayListener
} from "@mui/material";
import { Container } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, {useEffect, useRef} from "react";
import Signature from "../components/form/Signature";
import InspectionListItem from "../components/EEForm/InspectionListItem";
import CircuitDetailsItem from "../components/EEForm/CircuitDetailsItem";
import AdditionalChecksItem from "../components/EEForm/AdditionalChecksItem";
import {
    getFormData, getFormStatus,
    rejectSupervision,
    reportFormSign,
    reportFormSubmit, solveFormChangesNeeded,
    startSupervision,
    updateForm
} from "../api/formApi";
import {useLocation, useNavigate} from "react-router-dom";
import RecommendationsObservationsSection from "../components/EEForm/RecommendationsObservationsSection";
import FormCancelDialog from "../components/EEForm/FormCancelDialog";
import LoadingSkeletonCards from "../components/LoadingSkeletonCards";
import FormConfirmationDialog from "../components/EEForm/FormConfirmationDialog";
import useUser from "../hooks/user/useUser";
import UserRole from "../utils/UserRole";
import {
    addMonthsToDate,
    currentDate,
    formatDateFromMoment,
    formatDateToMoment
} from "../utils/DateUtils";
import {FormStatus} from "../utils/FormStatus";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import useErrorHandler from "../hooks/error/useErrorHandler";

const steps = ['Pasul 1', 'Pasul 2', 'Pasul 3', 'Pasul 4', 'Pasul 5'];

function DekraEEForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const setError = useErrorHandler();
    const {userHasRole, userHasAnyRole} = useUser();
    const [formData, setFormData] = React.useState({});
    const [formStatus, setFormStatus] = React.useState(null);
    const [loadingFormData, setLoadingFormData] = React.useState(false);
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
    const [supervisionStarted, setSupervisionStarted] = React.useState(false);
    const [submitButtonTooltip, setSubmitButtonTooltip] = React.useState(false);
    const formRef = useRef();

    const [activeStep, setActiveStep] = React.useState(0);
    const [reasonDate, setReasonDate] = React.useState(formData["reasonDate"] || currentDate());
    const [lastInspectionDate, setLastInspectionDate] = React.useState(formData["lastInspectionDate"] || currentDate());
    const [inspectorSignature,setInspectorSignature] = React.useState(formData["inspectorSignature"]);
    const [supervisorSignature,setSupervisorSignature] = React.useState(formData["supervisorSignature"]);
    const [inspectorSignatureDate, setInspectorSignatureDate] = React.useState(formData["inspectorSignatureDate"] || currentDate());
    const [supervisorSignatureDate, setSupervisorSignatureDate] = React.useState(formData["supervisorSignatureDate"] || currentDate());
    const [nextInspectionDate, setNextInspectionDate] = React.useState(formData["nextInspectionDate"] || currentDate());
    const [circuitItems, setCircuitItems] = React.useState([0]);
    const [testerSignature, setTesterSignature] = React.useState(formData["testerSignature"]);
    const [recommendationsObservations, setRecommendationsObservations] = React.useState([]);
    const [modificationsOrExtensions, setModificationsOrExtensions] = React.useState(false);

    const NUMERIC_FIELD_REGEX = new RegExp("^[0-9-.]+$");

      useEffect(() => {
        if (userHasRole(UserRole.SUPERVISOR) && !supervisionStarted) {
            setSubmitButtonDisabled(true);
        } else if (userHasRole(UserRole.SUPERVISOR) && supervisionStarted) {
            setSubmitButtonDisabled(false);
        }
    }, [userHasRole, supervisionStarted]);

    useEffect(() => {
        function initializeStateFields(data) {
            setTesterSignature(data["testerSignature"]);
            setInspectorSignature(data["inspectorSignature"]);
            setSupervisorSignature(data["supervisorSignature"]);
            setReasonDate(data["reasonDate"]);
            setLastInspectionDate(data["lastInspectionDate"]);
            setInspectorSignatureDate(data["inspectorSignatureDate"]);
            setSupervisorSignatureDate(data["supervisorSignatureDate"]);
            setNextInspectionDate(data["nextInspectionDate"]);
        }

        async function fetchFormData(formId) {
            try {
                setLoadingFormData(true);
                const data = await getFormData(formId)
                mapDtoToState(data)
                setFormData(data)
                initializeStateFields(data);
                await fetchFormStatus(formId);
                setLoadingFormData(false);
            } catch (e) {
                setError("Eroare la deschiderea formularului", e);
                navigate("/");
            }
        }

        async function fetchFormStatus(formId) {
            const data = await getFormStatus(formId)
            setFormStatus(data)
            if (data === FormStatus.SUPERVISION_STARTED || data === FormStatus.SUPERVISION_ENDED) {
                setSupervisionStarted(true);
            }
        }

        function shouldFetchFormData() {
            const path = location.pathname;
            const pattern = /^\/forms\/EE-form\/DKR\d{1,16}$/;
            return pattern.test(path);
        }

        function extractFormIdFromUrl() {
            const path = location.pathname;
            const pattern = /^\/forms\/EE-form\/(DKR\d{1,16})$/;
            const match = pattern.exec(path);
            return match ? match[1] : null;
        }

        if (shouldFetchFormData())
        {
            const formId = extractFormIdFromUrl()
            fetchFormData(formId);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // update date fields
        setFormData({
            ...formData,
            reasonDate,
            lastInspectionDate,
            inspectorSignatureDate,
            supervisorSignatureDate,
            nextInspectionDate,
            //testsDate,
            inspectorSignature,
            supervisorSignature,
            testerSignature
        })

        // eslint-disable-next-line
    }, [reasonDate, lastInspectionDate, inspectorSignatureDate, supervisorSignatureDate, inspectorSignature, supervisorSignature, testerSignature, nextInspectionDate])

    const openSubmitButtonTooltip = () => {
        setSubmitButtonTooltip(true);
    }

    const closeSubmitButtonTooltip = () => {
        setSubmitButtonTooltip(false);
    }

    const handleAddRecommendationsObservationsRow = () => {
        if (recommendationsObservations.length < 11) {
            setRecommendationsObservations([...recommendationsObservations, recommendationsObservations.length]);
        }
    }

    const handleRemoveRecommendationsObservationRow = () => {
        if (recommendationsObservations.length > 1) {
            const indexToRemove = recommendationsObservations.length - 1;
            setRecommendationsObservations([...recommendationsObservations.filter((el) => el !== indexToRemove)]);
            const data = {...formData};
            delete data[`reparations${indexToRemove}`];
            delete data[`reparationsCode${indexToRemove}`];
            delete data[`reparationsAdditional${indexToRemove}`];
            setFormData(data);
        }
    }

    const handleRemoveRecommendationsObservationsData = () => {
        const data = {...formData};
        for (let i = 0; i < 11; i++) {
            delete data[`reparations${i}`];
            delete data[`reparationsCode${i}`];
            delete data[`reparationsAdditional${i}`];
        }
        delete data[`immediateReparations`];
        delete data[`urgentReparations`];
        delete data[`furtherReparations`];
        delete data[`recommendedReparations`];
        delete data[`additionalRecommendedInspectionDate`];
        data[`recommendationsObservations`] = false
        setFormData(data);
        setRecommendationsObservations([]);
    }

    const ensureRecommendationsObservationsCount = (index = 1) => {
        let obs = []
        for (let i = 0; i < index; i++)
        {
            obs = [...obs, i];
        }
        setRecommendationsObservations([...obs]);
    }

    const ensureCircuitItemsCount = (index = 1) => {
        let obs = []
        for (let i = 0; i < index; i++)
        {
            obs = [...obs, i];
        }
        setCircuitItems([...obs]);
    }

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleInputChanged = (event) => {
        const target = event.target;
        const type = target.type;
        let value = type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (value === "true" || value === "false") {
            value = value === "true";
        }

        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleModificationsOrExtensionsInputChanged = (event) => {
        setModificationsOrExtensions(!modificationsOrExtensions);
        handleInputChanged(event);
    }

    const saveStepFormData = () => {
        const data = new FormData(formRef.current);
        const jsonData = Object.fromEntries(data);

        for (const [key, value] of Object.entries(jsonData)) {
            if (value === "true" || value === "false") {
                jsonData[key] = value === "true";
            }
        }

        setFormData({
            ...formData,
            ...jsonData
        })
    }

    const handleNext = (event) => {
        event.preventDefault()
        saveStepFormData();
        if (!isLastStep()) {
            setActiveStep(activeStep + 1);
        } else {
            setOpenConfirmationDialog(true);
        }
    };

    const handleBack = () => {
        saveStepFormData();
        setActiveStep(activeStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleCancel = () => {
        setOpenCancelDialog(true);
    }

    const handleCancelDialog = () => {
        navigate("/");
    }

    const handleCancelConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    }

    const handleContinueForm = () => {
        setOpenCancelDialog(false);
    }

    const handleStartSupervision = async () => {
        try {
            const formId = formData["formId"];
            await startSupervision(formId);
            setSupervisionStarted(true);
        } catch (e) {
            setError("Eroare la pornirea supervizarii", e);
        }
    }

    const handleAddCircuitItem = () => {
        if (circuitItems.length < 4) {
            setCircuitItems([...circuitItems, circuitItems.length]);
        }
    }

    const handleRemoveCircuitItem = () => {
        if (circuitItems.length > 1) {
            const indexToRemove = circuitItems.length - 1;
            setCircuitItems([...circuitItems.filter(el => el !== indexToRemove)]);
            const data = {...formData};
            delete data[`circuitName${indexToRemove}`];
            delete data[`circuitType${indexToRemove}`];
            delete data[`circuitMethod${indexToRemove}`];
            delete data[`circuitSectionLN${indexToRemove}`];
            delete data[`circuitSectionPE${indexToRemove}`];
            delete data[`circuitDisjunctorType${indexToRemove}`];
            delete data[`circuitDisjunctorNominalCurrent${indexToRemove}`];
            delete data[`circuitBreakingCurrent${indexToRemove}`];
            delete data[`circuitResidualCurrent${indexToRemove}`];
            delete data[`circuitOpTime${indexToRemove}`];
            delete data[`circuitOpTime5x${indexToRemove}`];
            delete data[`circuitMaxImpedance${indexToRemove}`];
            delete data[`circuitLL${indexToRemove}`];
            delete data[`circuitLN${indexToRemove}`];
            delete data[`circuitLPE${indexToRemove}`];
            delete data[`circuitNPE${indexToRemove}`];
            setFormData(data);
        }
    }

    function mapStateToDto(jsonData) {
        function handlePlaceType(jsonData) {
            jsonData["placeCommercial"] = jsonData["placeType"] === "placeCommercial";
            jsonData["placeIndustrial"] = jsonData["placeType"] === "placeIndustrial";
            jsonData["placePublic"] = jsonData["placeType"] === "placePublic";
            delete jsonData["placeType"];
        }

        function handleMeansOfGrounding(jsonData) {
            jsonData["groundingInstallationPlug"] = jsonData["meansOfGrounding"] === "groundingInstallationPlug";
            jsonData["providerInstallation"] = jsonData["meansOfGrounding"] === "providerInstallation";
            delete jsonData["meansOfGrounding"];
        }

        function handleDates(jsonData) {
            jsonData["reasonDate"] = formatDateFromMoment(jsonData["reasonDate"]);
            jsonData["lastInspectionDate"] = formatDateFromMoment(jsonData["lastInspectionDate"]);
            jsonData["inspectorSignatureDate"] = formatDateFromMoment(jsonData["inspectorSignatureDate"]);
            jsonData["supervisorSignatureDate"] = formatDateFromMoment(jsonData["supervisorSignatureDate"]);
            jsonData["nextInspectionDate"] = formatDateFromMoment(jsonData["nextInspectionDate"]);
            // jsonData["testsDate"] = formatDateFromMoment(jsonData["testsDate"]);
            jsonData["additionalRecommendedInspectionDate"] = formatDateFromMoment(jsonData["additionalRecommendedInspectionDate"]);
        }

        function handleFormType(jsonData) {
            jsonData["formType"] = "EE";
        }

        handleDates(jsonData);
        handlePlaceType(jsonData);
        handleMeansOfGrounding(jsonData);
        handleFormType(jsonData);
    }
    function mapDtoToState(jsonData) {
        function handleDates(jsonData) {
            jsonData["reasonDate"] = formatDateToMoment(jsonData["reasonDate"]);
            jsonData["lastInspectionDate"] = formatDateToMoment(jsonData["lastInspectionDate"]);
            jsonData["inspectorSignatureDate"] = formatDateToMoment(jsonData["inspectorSignatureDate"]);
            jsonData["supervisorSignatureDate"] = formatDateToMoment(jsonData["supervisorSignatureDate"]);
            jsonData["nextInspectionDate"] = jsonData["nextInspectionDate"] ? formatDateToMoment(jsonData["nextInspectionDate"])
                : addMonthsToDate(currentDate(), formData["numberOfMonthsUntilNextInspection"]);
            // jsonData["testsDate"] = formatDateToMoment(jsonData["testsDate"]);
            jsonData["additionalRecommendedInspectionDate"] = formatDateToMoment(jsonData["additionalRecommendedInspectionDate"]);
        }

        function handlePlaceType(jsonData) {
            if (jsonData["placeCommercial"] === true) {
                jsonData["placeType"] = "placeCommercial";
            } else if (jsonData["placeIndustrial"] === true) {
                jsonData["placeType"] = "placeIndustrial";
            } else if (jsonData["placePublic"] === true) {
                jsonData["placeType"] = "placePublic";
            } else {
                jsonData["placeType"] = "";
            }
            delete jsonData["placeCommercial"];
            delete jsonData["placeIndustrial"];
            delete jsonData["placePublic"];
        }

        function handleRecommendedObservations(jsonData) {
            if (jsonData["recommendationsObservations"] === true) {
                let index = 0;
                while (!!jsonData["reparationsAdditional" + index]
                    || !!jsonData["reparations" + index]
                    || !!jsonData["reparationsCode" + index]) {
                    index++;
                }
                ensureRecommendationsObservationsCount(index);
            }
        }

        function handleCircuitItems(jsonData) {
            let index = 0;
            while (!!jsonData["circuitBreakingCurrent" + index]
                || !!jsonData["circuitDisjunctorNominalCurrent" + index]
                || !!jsonData["circuitLL" + index]
                || !!jsonData["circuitLN" + index]
                || !!jsonData["circuitLPE" + index]
                || !!jsonData["circuitMaxImpedance" + index]
                || !!jsonData["circuitMethod" + index]
                || !!jsonData["circuitNPE" + index]
                || !!jsonData["circuitName" + index]
                || !!jsonData["circuitOpTime" + index]
                || !!jsonData["circuitOpTime5x" + index]
                || !!jsonData["circuitResidualCurrent" + index]
                || !!jsonData["circuitSectionLN" + index]
                || !!jsonData["circuitSectionPE" + index]
                || !!jsonData["circuitType" + index]
                || !!jsonData["circuitDisjunctorType" + index]) {
                index++;
            }
            ensureCircuitItemsCount(index);
        }

        function handleMeansOfGrounding(jsonData) {
            if (jsonData["groundingInstallationPlug"] === true) {
                jsonData["meansOfGrounding"] = "groundingInstallationPlug";
            } else if (jsonData["providerInstallation"] === true) {
                jsonData["meansOfGrounding"] = "providerInstallation";
            } else {
                jsonData["meansOfGrounding"] = "";
            }
            delete jsonData["groundingInstallationPlug"];
            delete jsonData["providerInstallation"];
        }

        handleCircuitItems(jsonData);
        handleRecommendedObservations(jsonData);
        handleMeansOfGrounding(jsonData);
        handlePlaceType(jsonData);
        handleDates(jsonData);
    }

    const handleSetNextInspectionDate = (event) => {
        setNextInspectionDate(addMonthsToDate(currentDate(), event.target.value))
    }

    const handleSaveForm = async () => {
        function shouldUpdateFormWithoutStateChangeForInspector() {
            return !!formId && formStatus === FormStatus.TO_REVIEW
        }

        function shouldUpdateFormWithoutStateChangeForSupervisor() {
            return !!formId && formStatus === FormStatus.SUPERVISION_ENDED;
        }

        function shouldUpdateFormToReview() {
            return !!formId && (userHasRole(UserRole.INSPECTOR) && formStatus === FormStatus.CHANGES_NEEDED)
        }

        const jsonData = {...formData}
        mapStateToDto(jsonData);
        const formId = jsonData["formId"];
        try {
            if (userHasRole(UserRole.INSPECTOR)) {
                if (shouldUpdateFormWithoutStateChangeForInspector()) {
                    await updateForm(formId, jsonData);
                } else if (shouldUpdateFormToReview()) {
                    await solveFormChangesNeeded(formId, jsonData);
                } else {
                    await reportFormSubmit(jsonData);
                }
            } else if (userHasRole(UserRole.SUPERVISOR)) {
                if (shouldUpdateFormWithoutStateChangeForSupervisor()) {
                    await updateForm(formId, jsonData);
                } else {
                    await reportFormSign(formId, jsonData);
                }
            } else if (userHasRole(UserRole.ADMIN)) {
                await updateForm(formId, jsonData);
            }
            navigate("/");
        } catch (e) {
            setError("Eroare la salvarea formularului", e);
        }

    }

    const handleChangesNeeded = async () => {
        try {
            const formId = formData["formId"];
            await rejectSupervision(formId);
            navigate("/");
        } catch (e) {
            setError("Eroare la salvarea formularului", e);
        }

    }

    function getSubmitButtonText() {
        if (!isLastStep())
            return 'Pasul urmator';
        if (userHasRole(UserRole.SUPERVISOR))
            return 'Finalizeaza supervizarea'
        return 'Finalizeaza raport'
    }

    useEffect(() => {
        document.getElementById('section-' + activeStep)?.scrollIntoView(false);
    }, [activeStep])

    return (
        <>
            {
                loadingFormData ?
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
                        <LoadingSkeletonCards/>
                    </Container>
                    :
                    <>
                        <FormCancelDialog handleCancelDialog={handleCancelDialog} handleContinueForm={handleContinueForm} openCancelDialog={openCancelDialog}/>
                        <FormConfirmationDialog handleCancel={handleCancelConfirmationDialog} handleSubmitForm={handleSaveForm} openDialog={openConfirmationDialog} handleChangesNeeded={handleChangesNeeded} status={formStatus}/>
                        <Paper sx={{background: 'white',
                            position: 'sticky',
                            top: 64,
                            bottom: 20,
                            paddingTop: '40px',
                            paddingBottom: '40px',
                            pl: 2,
                            pr: 2,
                            zIndex: 5,
                            overflowX: 'hidden',
                            maxWidth: '100%',
                            mb: 2
                        }}>
                            <Stepper activeStep={activeStep} sx={{height: 20}} >
                                {
                                    steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton onClick={handleStep(index)}>
                                                {label}
                                            </StepButton>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </Paper>
                        <Container component="form" onSubmit={handleNext} maxWidth="lg" sx={{ mt: 4, mb: 12, position: 'relative' }} ref={formRef} >
                            {
                                activeStep === 0 &&
                                <>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500'}} id="section-0">
                                            DATE REFERITOARE LA CLIENT
                                        </Typography>
                                        <TextField required id="clientName" label="Nume" name="clientName" defaultValue={formData["clientName"]} fullWidth sx={{ mt: 2, mb: 2 }}/>
                                        <TextField required id="clientAddress" label="Adresa" name="clientAddress" defaultValue={formData["clientAddress"]}  fullWidth sx={{ mb: 2 }}/>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500'}}>
                                            MOTIVUL ELABORARII ACESTUI RAPORT
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={8} lg={8}>
                                                <TextField required id="reason" name="reason" defaultValue={formData["reason"]} label="Motiv" fullWidth sx={{ mt: 2 }}/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="Data"
                                                        value={reasonDate}
                                                        onChange={(newValue) => {
                                                            if (!newValue) {
                                                                setReasonDate(newValue);
                                                            } else {
                                                                // TODO: set error
                                                            }
                                                        }}
                                                        id="reasonDate" name="reasonDate" fullWidth sx={{ mt: 2 }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3}}>
                                            DATE DESPRE INSTALATIE
                                        </Typography>
                                        <TextField required id="owner" label="Detinator" name="owner" defaultValue={formData["owner"]}  fullWidth sx={{ mt: 2, mb: 2 }}/>
                                        <TextField required id="ownerAddress" label="Adresa" name="ownerAddress" defaultValue={formData["ownerAddress"]}  fullWidth sx={{ mb: 2 }}/>
                                        <Typography component="p" sx={{fontWeight: '500'}}>
                                            Amplasarea / Descrierea locului
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={8} lg={8}>
                                                <FormControl>
                                                    <FormLabel id="placeGroupLabel">De uz: </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="place-radio-group"
                                                    >
                                                        <FormControlLabel name="placeType" checked={formData["placeType"] === "placeCommercial"} defaultChecked onChange={handleInputChanged} value={"placeCommercial"} control={<Radio required />} label="Comercial" />
                                                        <FormControlLabel name="placeType" checked={formData["placeType"] === "placeIndustrial"} onChange={handleInputChanged} value={"placeIndustrial"} control={<Radio required />} label="Industrial" />
                                                        <FormControlLabel name="placeType" checked={formData["placeType"] === "placePublic"} onChange={handleInputChanged} value={"placePublic"} control={<Radio required />} label="Public" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField id="placeOther" label="Altele" name="placeOther" defaultValue={formData["placeOther"]} fullWidth/>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" sx={{fontWeight: '500', mt: 2}}>
                                            Vechime estimata a sistemului de pozare
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="photoSystemAge" name="photoSystemAge" defaultValue={formData["photoSystemAge"]} label="Ani" fullWidth sx={{ mt: 2, mb: 2 }}/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4} sx={{ mt: 2, mb: 2 }}>
                                                <Typography component="p">
                                                    Prezinta modificari sau extinderi
                                                </Typography>
                                                <FormControlLabel
                                                    name="modificationsOrExtensions"
                                                    checked={formData["modificationsOrExtensions"] === true}
                                                    value={true}
                                                    onChange={handleModificationsOrExtensionsInputChanged}
                                                    control={<Checkbox />}
                                                    label="Da"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                {
                                                    modificationsOrExtensions &&
                                                    <TextField
                                                        required
                                                        id="modificationsOrExtensionsAge"
                                                        name="modificationsOrExtensionsAge"
                                                        defaultValue={formData["modificationsOrExtensionsAge"]}
                                                        label="Vechime estimata modificari/extideri"
                                                        fullWidth
                                                        sx={{ mt: 2, mb: 2 }}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography component="p" sx={{fontWeight: '500', mt: 2}}>
                                                    Data ultimei inspectii
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="Data"
                                                        value={lastInspectionDate}
                                                        onChange={(newValue) => {
                                                            setLastInspectionDate(newValue);
                                                        }}
                                                        id="lastInspectionDate"
                                                        name="lastInspectionDate"
                                                        sx={{ mt: 2, mb: 2 }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography component="p" sx={{fontWeight: '500', mt: 2}}>
                                                    Exista inregistrari ale instalatiei disponibile?
                                                </Typography>
                                                <FormControlLabel name="installationRegistration" onChange={handleInputChanged} checked={formData["installationRegistration"] === true} value={true} control={<Checkbox sx={{ mt: 2, mb: 2 }}/>} label="Da"/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3}}>
                                            MARIME SI LIMITARI ALE INSPECTIEI SI INCERCARILOR
                                        </Typography>
                                        <TextField required id="installationSize" label="Marimea instalatiei electrice la care se refera acest raport" name="installationSize" defaultValue={formData["installationSize"]}  fullWidth sx={{ mt: 2, mb: 2 }}/>
                                        <TextField required id="agreementLimitations" label="Limitarile stabilite prin acord, inclusiv motivarea" name="agreementLimitations" defaultValue={formData["agreementLimitations"]}  fullWidth sx={{ mb: 2 }}/>
                                        <TextField required id="exploitationLimitations" label="Limitari in exploatare, inclusiv motivarea" name="exploitationLimitations" defaultValue={formData["exploitationLimitations"]}  fullWidth sx={{ mb: 2 }}/>
                                        <Typography component="p">
                                            Inspecţia şi încercările detaliate în acest raport şi listele ataşate au fost efectuate în conformitate cu IEC 60364. <br/>
                                        </Typography>
                                        <Typography component="p" sx={{mt: 1}}>
                                            Se recomandă să se noteze faptul că pentru cablurile care sunt introduse în tuburi de protecţie şi canale de cabluri situate sub planşee
                                            şi, în general situate în structura clădirii sau subterane, nu au fost efectuate inspecţii, decât dacă s-a stabilit în mod special prin acord între
                                            client şi inspector înainte de inspecţie.
                                        </Typography>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3}}>
                                            URMĂTOAREA INSPECȚIE
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={8} lg={8} sx={{display: 'flex', alignItems: 'center'}}>
                                                <Typography component="p">
                                                    Vă recomandăm ca această instalație să fie testată și inspecționată după o perioadă de
                                                    maxim:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={2} lg={2}>
                                                <TextField required id="numberOfMonthsUntilNextInspection" defaultValue={formData["numberOfMonthsUntilNextInspection"]} onChange={handleSetNextInspectionDate} label="Luni" type={"number"} name="numberOfMonthsUntilNextInspection" fullWidth InputProps={{ inputProps: { min: 0} }}/>
                                            </Grid>
                                            <Grid item xs={6} md={2} lg={2}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        disabled
                                                        value={nextInspectionDate}
                                                        onChange={(newValue) => {
                                                            setNextInspectionDate(newValue);
                                                        }}
                                                        id="nextInspectionDate" name="nextInspectionDate" sx={{mb: 2}}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p">
                                            Cu condiția ca toate recomandările și observațiile care au codurile C1 și C2 sa fie remediate imediat, fără întârziere și, respectiv, cât mai
                                            curând posibil. Recomandările cu cod C3 sunt de efectuat de indată ce este posibil.
                                        </Typography>
                                    </Box>
                                </>
                            }
                            {
                                activeStep === 1 &&
                                <>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}} id="section-1">
                                            DATE DESPRE CONSTRUCTOR/INSTALATOR
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <TextField required id="builderName" label="Nume" defaultValue={formData["builderName"]} name="builderName" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="builderAddress" label="Adresa" defaultValue={formData["builderAddress"]} name="builderAddress" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="builderZip" label="Cod postal" defaultValue={formData["builderZip"]} name="builderZip" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="builderPhone1" label="Telefon" defaultValue={formData["builderPhone1"]} name="builderPhone1" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <TextField required id="builderPhone2" label="Telefon 2/Fax" defaultValue={formData["builderPhone2"]} name="builderPhone2" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="builderCUI" label="CUI" defaultValue={formData["builderCUI"]} name="builderCUI" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="builderCF" label="CF" defaultValue={formData["builderCF"]} name="builderCF" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="builderDepartment" label="Departament (dupa caz)" defaultValue={formData["builderDepartment"]} name="builderDepartment" fullWidth sx={{mb: 2}}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            CARACTERISTICI DE ALIMENTARE ŞI INSTALAŢII DE LEGARE LA
                                            PĂMÂNT
                                        </Typography>
                                        <Typography component="p" variant="h6">Instalatii de legare la pamant</Typography>
                                        <FormGroup row>
                                            <FormControlLabel name="groundingSystemTNS" value={true} checked={formData["groundingSystemTNS"] === true} onChange={handleInputChanged} control={<Checkbox />} label="TN-S" />
                                            <FormControlLabel name="groundingSystemTNCS" value={true} checked={formData["groundingSystemTNCS"] === true} onChange={handleInputChanged} control={<Checkbox />} label="TN-C-S" />
                                            <FormControlLabel name="groundingSystemTNC" value={true} checked={formData["groundingSystemTNC"] === true} onChange={handleInputChanged} control={<Checkbox />} label="TN-C" />
                                            <FormControlLabel name="groundingSystemTT" value={true} checked={formData["groundingSystemTT"] === true} onChange={handleInputChanged} control={<Checkbox />} label="TT" />
                                            <FormControlLabel name="groundingSystemIT" value={true} checked={formData["groundingSystemIT"] === true} onChange={handleInputChanged} control={<Checkbox />} label="IT" />
                                        </FormGroup>
                                        <Typography component="p" variant="h6" mt={2}>Număr și tip de conductoare active</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={10} lg={10}>
                                                <FormGroup row>
                                                    <FormControlLabel name="conductorNumberAndTypeCA" checked={formData["conductorNumberAndTypeCA"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="CA/curent alternativ" />
                                                    <FormControlLabel name="conductorNumberAndTypeMono" checked={formData["conductorNumberAndTypeMono"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Monofazat (2 conductoare)" />
                                                    <FormControlLabel name="conductorNumberAndTypeBi" checked={formData["conductorNumberAndTypeBi"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Monofazat (3 conductoare)" />
                                                    <FormControlLabel name="conductorNumberAndTypeTri" checked={formData["conductorNumberAndTypeTri"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Trifazat (4 conductoare)" />
                                                    <FormControlLabel name="conductorNumberAndTypeCC" checked={formData["conductorNumberAndTypeCC"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="CC/curent continuu" />
                                                    <FormControlLabel name="conductorNumberAndTypeTwoPols" checked={formData["conductorNumberAndTypeTwoPols"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="2 poli" />
                                                    <FormControlLabel name="conductorNumberAndTypeThreePols" checked={formData["conductorNumberAndTypeThreePols"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="3 poli" />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={2} lg={2} sx={{display: 'flex', alignItems: 'center'}}>
                                                <TextField id="conductorNumberAndTypeOther" label="Altul" defaultValue={formData["conductorNumberAndTypeOther"]} name="conductorNumberAndTypeOther" fullWidth/>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" variant="h6" mt={2}>Natura parametrilor de alimentare</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} md={6} lg={6}>
                                                <TextField
                                                    required
                                                    id="nominalTension"
                                                    label="(V) Tensiune nominală U"
                                                    defaultValue={formData["nominalTension"]} name="nominalTension"
                                                    fullWidth sx={{my: 2}}
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    required
                                                    id="nominalFrequency"
                                                    label="(Hz) Frecvență nominala f (1)"
                                                    defaultValue={formData["nominalFrequency"]} name="nominalFrequency"
                                                    fullWidth sx={{mb: 2}}
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    required
                                                    id="currentIpf"
                                                    label="(kA) Curent de defect prezumat Ipf (2/3)"
                                                    defaultValue={formData["currentIpf"]} name="currentIpf"
                                                    fullWidth sx={{mb: 2}}
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    required
                                                    id="impedance"
                                                    label="(Ω) Impedanța buclei externe Ze (3/4)"
                                                    defaultValue={formData["impedance"]} name="impedance"
                                                    fullWidth sx={{mb: 2}}
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6}>
                                                <List>
                                                    <ListItem>(1) documentație sau intreband producatorul</ListItem>
                                                    <ListItem>(2) documentație sau prin prin masurare</ListItem>
                                                    <ListItem>(3) în prezența mai multor surse se va trece valoarea/valorile mai mare/i</ListItem>
                                                    <ListItem>(4) Prin măsurare</ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" variant="h6" my={2}>Caracteristici ale dispozitivelor de protecţie a alimentării</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="chargingProtectionDeviceType" label="Tip" defaultValue={formData["chargingProtectionDeviceType"]} name="chargingProtectionDeviceType" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="shortCurrent"
                                                    label="(A) Curent de scurtcircuit Isc"
                                                    defaultValue={formData["shortCurrent"]} name="shortCurrent"
                                                    fullWidth
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="nominalCurrent"
                                                    label="(A) Curent nominala"
                                                    defaultValue={formData["nominalCurrent"]} name="nominalCurrent"
                                                    fullWidth
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            DETALII ALE INSTALAŢIEI LA CARE SE REFERĂ
                                            RAPORTUL
                                        </Typography>
                                        <FormControl required>
                                            <Typography component="p" variant="h6">Mijloace de legare la pământ:</Typography>
                                            <RadioGroup
                                                defaultValue={"providerInstallation"}
                                                row
                                                aria-labelledby="means-of-grounding-radio-group"
                                            >
                                                <FormControlLabel name="meansOfGrounding" checked={formData["meansOfGrounding"] === "providerInstallation"} onChange={handleInputChanged} value={"providerInstallation"} control={<Radio required/>} label="Instalația furnizorului" />
                                                <FormControlLabel name="meansOfGrounding" checked={formData["meansOfGrounding"] === "groundingInstallationPlug"} onChange={handleInputChanged} value={"groundingInstallationPlug"} control={<Radio required/>} label="Priza de pământ a instalatiei" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Typography component="p" variant="h6" my={2}>Detalii ale prizei de pământ a instalației (după caz):</Typography>
                                        <Grid container spacing={2} mb={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="groundingPlugType" label="Tip (ex. țăruș, bandă, tijă, etc.)" defaultValue={formData["groundingPlugType"]} name="groundingPlugType" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="groundingPlugLocation" label="Amplasare" defaultValue={formData["groundingPlugLocation"]} name="groundingPlugLocation" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="groundingPlugMaxIntake"
                                                    label="(kVA) Consum maxim"
                                                    defaultValue={formData["groundingPlugMaxIntake"]} name="groundingPlugMaxIntake"
                                                    fullWidth
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="groundingPlugResistance"
                                                    label="(Ω) Rezistența prizei față de pământ, RA"
                                                    defaultValue={formData["groundingPlugResistance"]} name="groundingPlugResistance"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="groundingPlugMeasuringMethod" label="Metoda măsurării" defaultValue={formData["groundingPlugMeasuringMethod"]} name="groundingPlugMeasuringMethod" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="groundingPlugMeasuresAgainstElectrocution" label="Măsuri de protecție contra electrocutării" defaultValue={formData["groundingPlugMeasuresAgainstElectrocution"]} name="groundingPlugMeasuresAgainstElectrocution" fullWidth/>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" variant="h6" my={2}>Întreruptor de reţea, DDR, siguranțe sau disjunctor</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="switchType" label="Tip (BS/EN)" defaultValue={formData["switchType"]} name="switchType" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="numberOfPols"
                                                    label="Număr de poli"
                                                    defaultValue={formData["numberOfPols"]}
                                                    name="numberOfPols"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField required id="switchMaterial" label="Material conductori de alimentare" defaultValue={formData["switchMaterial"]} name="switchMaterial" fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="switchConductorsSection"
                                                    label="(mm) Sectiune conductori de alimentare"
                                                    defaultValue={formData["switchConductorsSection"]} name="switchConductorsSection"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="switchNominalTension"
                                                    label="(V) Tensiune nominala"
                                                    defaultValue={formData["switchNominalTension"]} name="switchNominalTension"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="switchNominalCurrentIn"
                                                    label="(A) Curent nominal In"
                                                    defaultValue={formData["switchNominalCurrentIn"]} name="switchNominalCurrentIn"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="switchDifferentialCurrent"
                                                    label="(mA) Curent diferenţial rezidual nominal de funcţionare I∆n"
                                                    defaultValue={formData["switchDifferentialCurrent"]} name="switchDifferentialCurrent"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="switchMeasuredFunctioningTime"
                                                    label="(ms) Timp de functionare masurat (la I∆n)"
                                                    defaultValue={formData["switchMeasuredFunctioningTime"]} name="switchMeasuredFunctioningTime"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <TextField
                                                    required
                                                    id="switchDelay"
                                                    label="(ms) Temporizare, valoare nominala"
                                                    defaultValue={formData["switchDelay"]} name="switchDelay"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" variant="h6" my={2}>Conductoare de protecţie principale</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography component="p" mb={2} sx={{fontWeight: 500}}>Conductor de legare la pământ</Typography>
                                                <TextField required id="groundingConductorMaterial" label="Material" defaultValue={formData["groundingConductorMaterial"]} name="groundingConductorMaterial" fullWidth sx={{mb: 2}}/>
                                                <TextField
                                                    required
                                                    id="groundingConductorSection"
                                                    label="(mm) Sectiune"
                                                    defaultValue={formData["groundingConductorSection"]} name="groundingConductorSection"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth sx={{mb: 2}}
                                                />
                                                <FormControlLabel label="Verificare continuitate" control={<Checkbox />} value={true} name="groundingConductorContinuity" checked={formData["groundingConductorContinuity"] === true} onChange={handleInputChanged} sx={{mb: 2}}/>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography component="p" mb={2} sx={{fontWeight: 500}}>Conductoare de echipotențializare de protecție principale</Typography>
                                                <TextField required id="equipotentialConductorMaterial" label="Material" defaultValue={formData["equipotentialConductorMaterial"]} name="equipotentialConductorMaterial" fullWidth sx={{mb: 2}}/>
                                                <TextField
                                                    required
                                                    id="equipotentialConductorSection"
                                                    label="(mm) Sectiune"
                                                    defaultValue={formData["equipotentialConductorSection"]} name="equipotentialConductorSection"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth sx={{mb: 2}}
                                                />
                                                <FormControlLabel label="Verificare continuitate" control={<Checkbox />} checked={formData["equipotentialConductorContinuity"] === true} onChange={handleInputChanged} value={true} name="equipotentialConductorContinuity" sx={{mb: 2}}/>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" sx={{fontWeight: 500}}>Conectarea protecției la alte parți conductive străine</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormGroup>
                                                    <FormControlLabel name="gasPipes" checked={formData["gasPipes"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="La conductele care intră pentru alimentare cu gaz"/>
                                                    <FormControlLabel name="waterPipes" checked={formData["waterPipes"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="La conductele care intră pentru alimentare cu apă"/>
                                                    <FormControlLabel name="petrolPipes" checked={formData["petrolPipes"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="La conductele care intră pentru alimentare cu produse petroliere"/>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormGroup>
                                                    <FormControlLabel name="thunderProtection" checked={formData["thunderProtection"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="La protecţia împotriva trăsnetului"/>
                                                    <FormControlLabel name="steelProtection" checked={formData["steelProtection"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="La armătura de oţel (oţel-beton)"/>
                                                    <TextField name="otherProtection" defaultValue={formData["otherProtection"]} id="otherProtection" label="La instalațiile altor furnizori/servicii care intră în instalație (specificați)"/>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            }
                            {
                                activeStep === 2 &&
                                <>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}} id="section-2">
                                            LISTĂ DE INSPECȚIE PENTRU TABLOURI ȘI CIRCUITE
                                        </Typography>
                                        <Typography component="p" sx={{fontWeight: 500, mb: 1}}>Legenda: (se vor completa toate casutele)</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Typography component="p">✓ - Indica condiția „Acceptabil”</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Typography component="p">LIM - Indică o limitare</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Typography component="p">N/A - Indică Nu se aplică/Not applicable</Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" variant="h6" mt={2}>Condiția și conformitatea echipamentelor si materialelor branșării la furnizor</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <InspectionListItem formData={formData} text={'Starea cablului de branșament'} prefix={'1'} itemNumber={'1'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Întreruptor/Siguranţă fuzibilă de alimentare'} prefix={'1'} itemNumber={'2'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Contoare de distribuitor – Distribuitor'} prefix={'1'} itemNumber={'3'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Contoare de consumator – Consumator'} prefix={'1'} itemNumber={'4'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Echipament de contorizare'} prefix={'1'} itemNumber={'5'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Izolație / Separator (daca există)'} prefix={'1'} itemNumber={'6'} display={"list-item"}/>
                                        </List>
                                        <Typography component="p" variant="h6" mt={2}>Prezența unei configuraţii adecvate pentru alte surse/generatoare care funcţionează
                                            în paralel cu reţeaua publică de alimentare cu energie electrică</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <InspectionListItem formData={formData} text={'Conectarea corectă în paralel a generatorului'} prefix={'2'} itemNumber={'1'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Mijloace de secţionare a generatorului de la reţeaua publică de alimentare cu energie electrică'} prefix={'2'} itemNumber={'2'} display={"list-item"}/>
                                        </List>
                                        <Typography component="p" variant="h6" mt={2}>Configurația legării la pământ și a altor conexiuni de protecție</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <ListItem sx={{display: "list-item"}}>
                                                <List sx={{listStyle: "disc", pl: 2}}>
                                                    <InspectionListItem formData={formData} text={'Există și corespunde aplicației legarea la pământ a furnizorului'} prefix={'3'} itemNumber={'1'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Există și corespund aplicației electrozii de legare la pământ'} prefix={'3'} itemNumber={'2'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Dimensionarea conductorului de împământare corespunde aplicației'} prefix={'3'} itemNumber={'3'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Conexiunile conductorului de împământare corespund aplicației'} prefix={'3'} itemNumber={'4'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Posibilitatea de acces la conexiunile conductorului de împământare'} prefix={'3'} itemNumber={'5'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Dimensionarea conductorului de protecție corespunde aplicației'} prefix={'3'} itemNumber={'6'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Conexiunile conductorului de protecție corespund aplicației'} prefix={'3'} itemNumber={'7'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Posibilitatea de acces la conexiunile conductorului de protecție'} prefix={'3'} itemNumber={'8'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Prezența etichetelor si marcajelor în toate locurile corespunzătoare'} prefix={'3'} itemNumber={'9'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>TFJF/FELV</Typography>
                                                <List sx={{listStyle: "disc", pl: 2}}>
                                                    <InspectionListItem formData={formData} text={'Alimentarea este făcuta cu o sursa care are cel puțin izolatie simplă'} prefix={'3'} itemNumber={'10'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Prizele și conectorii nu se pot folosi în mod greșit prin inserarea în alte echipamente prezente în încăpere'} prefix={'3'} itemNumber={'11'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Tensiune joasă redusă</Typography>
                                                <List sx={{listStyle: "disc", pl: 2}}>
                                                    <InspectionListItem formData={formData} text={'Prezența unei surse corespunzătoare '} prefix={'3'} itemNumber={'12'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Prizele și conectorii nu se pot folosi în mod greșit prin introducerea în alte echipamente prezente în încăpere'} prefix={'3'} itemNumber={'13'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                        </List>
                                        <Typography component="p" variant="h6" mt={2}>Alte măsuri de protecție</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <InspectionListItem formData={formData} text={'Izolație dublă'} prefix={'4'} itemNumber={'1'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Izolație întărită'} prefix={'4'} itemNumber={'2'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Obstacole'} prefix={'4'} itemNumber={'3'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Amplasare în afara zonei de accesibilitate la atingere'} prefix={'4'} itemNumber={'4'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Mediu neconductor'} prefix={'4'} itemNumber={'5'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Legătură de echipotenţializare locală legată la pământ'} prefix={'4'} itemNumber={'6'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Separare electrică'} prefix={'4'} itemNumber={'7'} display={"list-item"}/>
                                        </List>
                                        <Typography component="p" variant="h6" mt={2}>Echipament de distribuție</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <InspectionListItem formData={formData} text={'Caracterul adecvat al spațiului de lucru / posibilitatea de acces la echipament'} prefix={'5'} itemNumber={'1'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Fixări sigure'} prefix={'5'} itemNumber={'2'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Izolația părților active nu este deteriorată'} prefix={'5'} itemNumber={'3'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Starea barierelor de protecție'} prefix={'5'} itemNumber={'4'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Carcasele sunt corespunzătoare pentru gradul de protecție IP'} prefix={'5'} itemNumber={'5'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Carcasele sunt corespunzătoare pentru rezistență la foc'} prefix={'5'} itemNumber={'6'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Carcasele nu sunt deteriorate, nu induc riscuri'} prefix={'5'} itemNumber={'7'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența unuia sau mai multor întreruptoare de reţea, conectate dacă este necesar'} prefix={'5'} itemNumber={'8'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Funcţionarea unuia sau mai multor întreruptoare de reţea (verificare de funcţionare)'} prefix={'5'} itemNumber={'9'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Dispozitivele de protecție sunt identificate corect'} prefix={'5'} itemNumber={'10'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Sistemele de protecție sunt dimensionate în funcție de curentul de defect prevăzut'} prefix={'5'} itemNumber={'11'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Se verifică prezența DDR/RCD montate pentru protectia la electrocutare (RCD sau RCBO)'} prefix={'5'} itemNumber={'12'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Se verifică prezența DDR/RCD montate pentru protecție suplimentară (RCD sau RCBO)'} prefix={'5'} itemNumber={'13'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Se verifică prezența DDR/RCD montate pentru protecție la incendiu (RCD sau RCBO)'} prefix={'5'} itemNumber={'14'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Manevră manuală a întreruptoarelor automate (disjunctoarelor) şi a DDR pentru a demonstra funcţionarea lor'} prefix={'5'} itemNumber={'15'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența unui aviz de încercare trimestrială a DDR la sau în apropierea Punctului de origine'} prefix={'5'} itemNumber={'16'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența schemelor, diagramelor sau listelor pe sau în apropierea fiecărui tablou de distribuţie sau echipament, dacă este necesar '} prefix={'5'} itemNumber={'17'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența unei note de avertizare referitoare la culorile cablurilor (combinate) nestandardizate pe sau în apropierea fiecărui tablou de distribuţie, dacă este necesar '} prefix={'5'} itemNumber={'18'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența unei note de avertizare referitoare la altă sursă de alimentare pe sau în apropierea acesteia'} prefix={'5'} itemNumber={'19'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența unei etichete de recomandare pentru următoarea inspecţie'} prefix={'5'} itemNumber={'20'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența altor etichetări necesare (specificați)'} prefix={'5'} itemNumber={'21'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Examinarea dispozitivelor de protecţie; tip şi caracteristici nominale corecte (nu există semne de deteriorare termică, arc electric sau de supraîncălzire inacceptabilă) '} prefix={'5'} itemNumber={'22'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Protecție contra deteriorării mecanice la punctele de intrare a cablurilor în echipamente'} prefix={'5'} itemNumber={'23'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Protecție contra efectelor electromagnetice la punctele de intrare a cablurilor în carcase feromagnetice'} prefix={'5'} itemNumber={'24'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența dispozitivelor de protecţie monopolare numai pe conductorul de linie'} prefix={'5'} itemNumber={'25'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Confirmarea indicaţiei funcţionării protecţiei la supratensiuni (SPD), dacă este prevăzută'} prefix={'5'} itemNumber={'26'} display={"list-item"}/>
                                        </List>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="p" variant="h6" mt={2}>Distribuție / circuite</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <InspectionListItem formData={formData} text={'Identificarea conductoarelor'} prefix={'6'} itemNumber={'1'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cablurile sunt corect susţinute pe întreg traseul'} prefix={'6'} itemNumber={'2'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Examinarea izolaţiei părţilor active'} prefix={'6'} itemNumber={'3'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cablurile fără manta sunt protejate prin montare în tuburi de protecţie, în sisteme de tuburi sau jgheaburi profilate.'} prefix={'6'} itemNumber={'4'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Sistemele de susţinere corespund aplicaţiei (inclusiv tuburi flexibile)'} prefix={'6'} itemNumber={'5'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Extremităţile cablurilor corect fixate în carcase'} prefix={'6'} itemNumber={'6'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Examinarea cablurilor pentru a determina existenţa semnelor de deteriorare mecanică sau termică'} prefix={'6'} itemNumber={'7'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cablurile sunt corespunzătoare pentru curenţii admisibili cu referire la tipul şi natura instalaţiei'} prefix={'6'} itemNumber={'8'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Dispozitivele de protecţie sunt corespunzătoare aplicaţiei: tip şi curent nominal de defect pentru protecţia în caz de defect '} prefix={'6'} itemNumber={'9'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența conductoarelor circuitelor de protecţie şi ele corespund aplicaţiei'} prefix={'6'} itemNumber={'10'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Coordonarea între conductoare și dispozitivele de protecție la suprasarcină'} prefix={'6'} itemNumber={'11'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Practici de instalare a cablurilor şi moduri de pozare în corespondență cu tipul şi natura instalaţiei, precum şi cu influenţele externe'} prefix={'6'} itemNumber={'12'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cablurile expuse la radiație solară directă sunt de tip corespunzător'} prefix={'6'} itemNumber={'13'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cablurile ascunse sunt instalate în zonele recomandate (vezi secțiunea Marime si limitări)'} prefix={'6'} itemNumber={'14'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cabluri ascunse care încorporează o armătură sau un înveliș cu împământare sau care circulă în cadrul unui sistem de cabluri cu împământare sau care sunt protejate în alt mod împotriva deteriorării mecanice cauzate de cuie, șuruburi și altele asemenea, atunci când nu se află în zonele prescrise sau nu sunt protejate de un RCD de 30 mA (a se vedea domeniul de aplicare și limitările).'} prefix={'6'} itemNumber={'15'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Prezența protecției suplimentare prin DDR/RCD de max. 30 mA pentru cablurile ascunse în pereți sau planșee la o adancime mai mică de 50mm'} prefix={'6'} itemNumber={'16'} display={"list-item"}/>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Prezența unei protecții suplimentare DDR/RCD de max. 30 mA pentru:</Typography>
                                                <List sx={{listStyle: 'disc', pl:2}}>
                                                    <InspectionListItem formData={formData} text={'Pentru circuite de alimentare ale unui echipament mobil având curentul nominal care nu depăşeşte 32 A pentru utilizare în exterior în toate cazurile'} prefix={'6'} itemNumber={'17'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Pentru toate prizele având curentul nominal mai mic sau egal cu 20 A destinate să fie utilizate de persoane obişnuite fără excepţie'} prefix={'6'} itemNumber={'18'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Pentru cabluri situate în pereţi sau planșee cu părți metalice, indifierent de adancime'} prefix={'6'} itemNumber={'19'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <InspectionListItem formData={formData} text={'Prezența barierelor pentru oprirea focului, a măsuri de etanşare şi protecţiei împotriva efectelor termice'} prefix={'6'} itemNumber={'20'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cabluri din domeniul de tensiuni II grupate separat/izolate faţă de cablurile din domeniul de tensiuni I'} prefix={'6'} itemNumber={'21'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Cabluri grupate separat/izolate faţă de serviciile neelectrice'} prefix={'6'} itemNumber={'22'} display={"list-item"}/>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Terminaţii ale cablurilor la nivelul carcaselor – numere de identificare/de înregistrare şi
                                                    amplasarea elementelor inspectate</Typography>
                                                <List sx={{listStyle: 'disc', pl:2}}>
                                                    <InspectionListItem formData={formData} text={'Conexiuni fără solicitări excesive'} prefix={'6'} itemNumber={'23'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Nicio izolaţie de bază a unui conductor vizibilă în exteriorul carcasei'} prefix={'6'} itemNumber={'24'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Conexiuni ale conductoarelor active închise în mod corespunzător în carcasă'} prefix={'6'} itemNumber={'25'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Conexiuni corespunzătoare în punctele de intrare în carcasă (presetupe, treceri izolate, etc.)'} prefix={'6'} itemNumber={'26'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <InspectionListItem formData={formData} text={'Starea generală a sistemelor de pozare'} prefix={'6'} itemNumber={'27'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Temperatura nominală a izolației cablurilor'} prefix={'6'} itemNumber={'28'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Starea accesoriilor inclusiv prize de curent, întreruptoare şi cutii de joncţiune'} prefix={'6'} itemNumber={'29'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Accesoriile circuitului sunt corespunzătoare pentru influenţe externe '} prefix={'6'} itemNumber={'30'} display={"list-item"}/>
                                        </List>
                                        <Typography component="p" variant="h6" mt={2}>Secţionare şi întrerupere</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Separatoare</Typography>
                                                <List sx={{listStyle: 'disc', pl:2}}>
                                                    <InspectionListItem formData={formData} text={'Verificarea prezenței și a stării dispozitivelor corespunzătoare'} prefix={'7'} itemNumber={'1'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Amplasare acceptabilă – să se indice dacă este amplasat local sau la distanţă faţă de echipamentul respective'} prefix={'7'} itemNumber={'2'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Capabilitatea de a fi fixat în poziţia FĂRĂ TENSIUNE'} prefix={'7'} itemNumber={'3'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Verificarea funcționării corecte'} prefix={'7'} itemNumber={'4'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Identificare clară prin poziţie şi/sau marcare durabilă'} prefix={'7'} itemNumber={'5'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Etichetă de avertizare postată în situaţiile în care părţile active nu pot fi secţionate prin funcţionarea unui singur dispozitiv '} prefix={'7'} itemNumber={'6'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Deconectare pentru mentenanţă mecanică</Typography>
                                                <List sx={{listStyle: 'disc', pl:2}}>
                                                    <InspectionListItem formData={formData} text={'Verificarea prezenței și a stării dispozitivelor corespunzătoare'} prefix={'7'} itemNumber={'7'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Amplasare acceptabilă – să se indice dacă este amplasat local sau la distanţă faţă de echipamentul respectiv'} prefix={'7'} itemNumber={'8'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Capabilitatea de a fi fixat în poziţia FĂRĂ TENSIUNE'} prefix={'7'} itemNumber={'9'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Verificarea funcționării corecte'} prefix={'7'} itemNumber={'10'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Identificare clară prin poziţie şi/sau marcare durabilă'} prefix={'7'} itemNumber={'11'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Întrerupere/oprire de urgenţă</Typography>
                                                <List sx={{listStyle: 'disc', pl:2}}>
                                                    <InspectionListItem formData={formData} text={'Verificarea prezenței și a stării dispozitivelor corespunzătoare'} prefix={'7'} itemNumber={'12'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Uşor accesibil pentru funcţionare în caz de pericol'} prefix={'7'} itemNumber={'13'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Verificarea funcționării corecte'} prefix={'7'} itemNumber={'14'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Identificare clară prin poziţie şi/sau marcare durabilă'} prefix={'7'} itemNumber={'15'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                            <ListItem sx={{display: "list-item"}}>
                                                <Typography component="p" variant="h6" mt={2}>Întrerupere funcţională</Typography>
                                                <List sx={{listStyle: 'disc', pl:2}}>
                                                    <InspectionListItem formData={formData} text={'Verificarea prezenței și a stării dispozitivelor corespunzătoare'} prefix={'7'} itemNumber={'16'} display={"list-item"}/>
                                                    <InspectionListItem formData={formData} text={'Verificarea funcționării corecte'} prefix={'7'} itemNumber={'17'} display={"list-item"}/>
                                                </List>
                                            </ListItem>
                                        </List>
                                        <Typography component="p" variant="h6" mt={2}>Echipament de utilizare curentă (conectat în mod permanent)</Typography>
                                        <List sx={{listStyle: "numbered", pl: 2}}>
                                            <InspectionListItem formData={formData} text={'Starea echipamentului pentru gradul de protecţie IP'} prefix={'8'} itemNumber={'1'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Starea echipamentului pentru gradul de rezistență la foc'} prefix={'8'} itemNumber={'2'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Carcasa nu este avariată/deteriorată pentru a afecta securitatea'} prefix={'8'} itemNumber={'3'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Echipamentul este corespunzător pentru mediu şi influenţele externe'} prefix={'8'} itemNumber={'4'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Fixări sigure'} prefix={'8'} itemNumber={'5'} display={"list-item"}/>
                                            <InspectionListItem formData={formData} text={'Orificii de intrare a cablurilor în plafoane deasupra corpurilor de iluminat, dimensionate sau etanşate astfel încât să se limiteze propagarea focului'} prefix={'8'} itemNumber={'6'} display={"list-item"}/>
                                        </List>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3}}>
                                            SINTEZA STARII INSTALATIEI
                                        </Typography>
                                        <Typography component="p" sx={{fontWeight: '500'}}>
                                            Starea generala a instalatiei (in ceea ce priveste securitatea electrica)
                                        </Typography>
                                        <TextField required id="electricalSecurityGeneralState" name="electricalSecurityGeneralState" defaultValue={formData["electricalSecurityGeneralState"]}  fullWidth multiline minRows={5} maxRows={10}/>
                                        <Typography component="p" sx={{fontWeight: '500', mt: 3}}>
                                            Evaluarea per ansamblu a instalatiei in ceea ce priveste aptitudinea de a fi utilizata in continuare
                                        </Typography>
                                        <FormControl required>
                                            <RadioGroup
                                                defaultValue={"satisfacator"}
                                                row
                                                aria-labelledby="instllation-further-usage-radio-group"
                                            >
                                                <FormControlLabel name="installationFurtherUsage" checked={formData["installationFurtherUsage"] === "satisfacator"} onChange={handleInputChanged} value={"satisfacator"} control={<Radio required/>} label="Satisfacator" />
                                                <FormControlLabel name="installationFurtherUsage" checked={formData["installationFurtherUsage"] === "nesatisfacator"} onChange={handleInputChanged} value={"nesatisfacator"} control={<Radio required/>} label="Nesatisfacator" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Typography component="p">
                                            O evaluare nesatisfăcătoare arată că au fost identificate condiţii periculoase şi/sau potenţial periculoase.
                                        </Typography>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3}}>
                                            RECOMANDARI SI OBSERVATII
                                        </Typography>
                                        <Typography component="p">
                                            Cu referire la listele de inspecții și listele rezultatelor încercărilor ataşate şi în funcţie de limitele specificate la
                                            secţiunea „Mărime şi limitările inspecţiei şi încercărilor”.
                                        </Typography>
                                        <FormControl required>
                                            <RadioGroup
                                                defaultValue={false}
                                                row
                                                aria-labelledby="reparations-radio-group"
                                            >
                                                <FormControlLabel
                                                    name="recommendationsObservations"
                                                    checked={formData["recommendationsObservations"] === false}
                                                    value={false}
                                                    control={<Radio required/>}
                                                    label="Nu este necesara nicio masura de remediere"
                                                    onChange={() => {
                                                        handleRemoveRecommendationsObservationsData();
                                                    }}
                                                />
                                                <FormControlLabel
                                                    name="recommendationsObservations"
                                                    value={true}
                                                    checked={formData["recommendationsObservations"] === true}
                                                    control={<Radio required/>}
                                                    label="Exista urmatoarele observatii si recomandari"
                                                    onChange={(event) => {
                                                        handleInputChanged(event)
                                                        ensureRecommendationsObservationsCount();
                                                    }}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {
                                            formData["recommendationsObservations"] === true &&
                                            <RecommendationsObservationsSection formData={formData} setFormData={setFormData} handleInputChanged={handleInputChanged} recommendationsObservations={recommendationsObservations} handleAddRow={handleAddRecommendationsObservationsRow} handleRemoveRow={handleRemoveRecommendationsObservationRow}/>
                                        }
                                    </Box>
                                </>
                            }
                            {
                                activeStep === 3 &&
                                <>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}  id="section-3">
                                            LISTA ÎNCERCĂRILOR
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormGroup>
                                                    <FormControlLabel name="externalGroundingLoopImpedance" checked={formData["externalGroundingLoopImpedance"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Măsurarea impedanţei buclei de punere la pământ externe, Ze"/>
                                                    <FormControlLabel name="groundingPlugResistanceMeasuring" checked={formData["groundingPlugResistanceMeasuring"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Măsurarea rezistenţei prizei de pământ, Ra"/>
                                                    <FormControlLabel name="protectionPipesContinuity" checked={formData["protectionPipesContinuity"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Continuitatea conductoarelor de protecție"/>
                                                    <FormControlLabel name="isolationResistanceBetweenLineConductors" checked={formData["isolationResistanceBetweenLineConductors"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Rezistența de izolație între conductoarele de linie"/>
                                                    <FormControlLabel name="isolationResistanceBetweenPhaseAndGround" checked={formData["isolationResistanceBetweenPhaseAndGround"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Rezistența de izolație între conductoarele de fază și pământ"/>
                                                    <FormControlLabel name="electricalSeparationProtection" checked={formData["electricalSeparationProtection"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Protecţie prin separare electrică"/>
                                                    <FormControlLabel name="tensionDropChecked" checked={formData["tensionDropChecked"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Verificarea căderii de tensiune"/>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormControlLabel name="directProtectionTried" checked={formData["directProtectionTried"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Incercarea protecției la contact direct prin barierele, obstacolele, carcasele, existente la momentul instalarii. "/>
                                                <FormControlLabel name="floorAndWallResistance" checked={formData["floorAndWallResistance"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Rezistenţa de izolaţie/impedanţa planşeelor şi pereţilor"/>
                                                <FormControlLabel name="polarityCheck" checked={formData["polarityCheck"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Verificarea polarităților"/>
                                                <FormControlLabel name="defectGroundingLoopImpedanceMeasure" checked={formData["defectGroundingLoopImpedanceMeasure"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Măsurarea impedanţei buclei de defect de punere la pământ, Zs"/>
                                                <FormControlLabel name="sequenceCheck" checked={formData["sequenceCheck"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Verificarea respectării succesiunii fazelor"/>
                                                <FormControlLabel name="residualDeviceFunctionality" checked={formData["residualDeviceFunctionality"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Funcționarea dispozitivelor de current residual (DDR)"/>
                                                <FormControlLabel name="ensembleFunctioningCheck" checked={formData["ensembleFunctioningCheck"] === true} onChange={handleInputChanged} value={true} control={<Checkbox />} label="Încercări de funcţionare ale ansamblurilor"/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            INSTRUMENTE DE ÎNCERCARE UTILIZATE
                                        </Typography>
                                        <TextField required id="defectGroundingLoopImpedance" label="Impedanţa buclei de defect de punere la pământ" defaultValue={formData["defectGroundingLoopImpedance"]} name="defectGroundingLoopImpedance" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="resistanceAndIsolation" label="Rezistenţa de izolaţie" defaultValue={formData["resistanceAndIsolation"]} name="resistanceAndIsolation" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="continuity" label="Continuitate" defaultValue={formData["continuity"]} name="continuity" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="ddr" label="DDR" defaultValue={formData["ddr"]} name="ddr" fullWidth sx={{mb: 2}}/>
                                        <TextField id="otherTries" label="Alte încercări" defaultValue={formData["otherTries"]} name="otherTries" fullWidth sx={{mb: 2}}/>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            DETALII ALE TABLOULUI DE DISTRIBUŢIE
                                        </Typography>
                                        <Typography component="p" sx={{fontWeight: '500'}}>
                                            SE COMPLETEAZĂ TOTDEAUNA
                                        </Typography>
                                        <TextField required id="distributionPanelNumber" label="Nr. / ref. tablou" defaultValue={formData["distributionPanelNumber"]} name="distributionPanelNumber" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="distributionPanelPlacement" label="Amplasare" defaultValue={formData["distributionPanelPlacement"]} name="distributionPanelPlacement" fullWidth sx={{mb: 2}}/>
                                        <Typography component="p" sx={{fontWeight: '500'}}>
                                            SE COMPLETEAZĂ NUMAI CÂND TABLOUL NU SE CONECTEAZĂ
                                            DIRECT LA PUNCTUL DE ORIGINE AL INSTALAȚIEI
                                        </Typography>
                                        <TextField id="distributionPanelPoweredBy" label="Este alimentat de la" defaultValue={formData["distributionPanelPoweredBy"]} name="distributionPanelPoweredBy" fullWidth sx={{mb: 2}}/>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <TextField id="supracurentDevice" label="Dispozitiv de protecție la supracurenți" defaultValue={formData["supracurentDevice"]} name="supracurentDevice" fullWidth sx={{mb: 2}}/>
                                                <FormControl fullWidth>
                                                    <InputLabel id="ddr-select-label">DDR/RCD (daca exista)</InputLabel>
                                                    <Select defaultValue={formData["ddrRcd"] || " "} name="ddrRcd" id="ddrRcd" labelId="ddrRcd-select-label"
                                                            sx={{mb: 2}} label="DDR/RCD (daca exista)">
                                                        <MenuItem disabled value={" "}>Alegeti</MenuItem>
                                                        <MenuItem value={"N/A"}>N/A</MenuItem>
                                                        <MenuItem value={"DDR"}>DDR</MenuItem>
                                                        <MenuItem value={"RCD"}>RCD</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField id="distributionPanelType" label="Tip" defaultValue={formData["distributionPanelType"]} name="distributionPanelType" fullWidth sx={{mb: 2}}/>
                                                <TextField
                                                    id="defectCurrent"
                                                    label="(mA) Curent de defect"
                                                    defaultValue={formData["defectCurrent"]} name="defectCurrent"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <TextField
                                                    id="nominalPanelCurrent"
                                                    label="(A) Curent nominal"
                                                    defaultValue={formData["nominalPanelCurrent"]} name="nominalPanelCurrent"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth sx={{mb: 2}}
                                                />
                                                <TextField
                                                    id="nominalPanelTension"
                                                    label="(V) Tensiune nominală"
                                                    defaultValue={formData["nominalPanelTension"]} name="nominalPanelTension"
                                                    onKeyDown={(event) => {
                                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    fullWidth sx={{mb: 2}}
                                                />
                                                <TextField type={"number"} id="panelPhaseNumber" label="Număr de faze" defaultValue={formData["panelPhaseNumber"]} name="panelPhaseNumber" fullWidth sx={{mb: 2}}/>
                                                <TextField type={"number"} id="panelPolNumber" label="Număr de poli" defaultValue={formData["panelPolNumber"]} name="panelPolNumber" fullWidth sx={{mb: 2}}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            DETALII ALE CIRCUITELOR
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item container spacing={2} xs={11} md={11} lg={11}>
                                                <List>
                                                    {circuitItems.map((ciruitItem, i) => (
                                                        <CircuitDetailsItem formData={formData} key={i} index={i}/>
                                                    ))}
                                                </List>
                                            </Grid>
                                            <Grid item xs={1} md={1} lg={1}>
                                                <Button variant={"contained"} sx={{fontSize : 24}} disabled={circuitItems.length === 4} onClick={handleAddCircuitItem}>+</Button>
                                                <Button variant={"outlined"} color={"inherit"} sx={{fontSize : 24, mt: 2}} disabled={circuitItems.length === 1} onClick={handleRemoveCircuitItem}>-</Button>
                                            </Grid>
                                        </Grid>
                                        <Typography component="p" fontWeight={500}>CODURI PENTRU TIPURILE DE CABLU</Typography>
                                        <Grid container spacing={4}>
                                            <Grid item xs={2}>
                                                <Typography component="p"><b>A</b> - Cabluri PVC/PVC</Typography>
                                                <Typography component="p"><b>B</b> - Cabluri PVC în tub metalic </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography component="p"><b>C</b> - Cabluri PVC în tub nemetalic </Typography>
                                                <Typography component="p"><b>D</b> - Cabluri PVC în canal metalic</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography component="p"><b>E</b> - Cabluri PVC în canal nemetalic</Typography>
                                                <Typography component="p"><b>F</b> - Cabluri PVC armate SWA</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography component="p"><b>G</b> - Cabluri XLPE Armate SWA </Typography>
                                                <Typography component="p"><b>H</b> - Cabluri cu izolație minerală</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField id="otherCableType" label="O (altceva)" defaultValue={formData["otherCableType"]} name="otherCableType" fullWidth sx={{mb: 2}}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            INSTRUMENTE DE ÎNCERCARE FOLOSITE
                                            LA ACEST TABLOU (nr. serie sau alt identificator unic)
                                        </Typography>
                                        <TextField required id="continuityUsed" label="Continuitate" defaultValue={formData["continuityUsed"]} name="continuityUsed" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="isolationResistanceUsed" label="Rezistența izolației" defaultValue={formData["isolationResistanceUsed"]} name="isolationResistanceUsed" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="defectLoopCurrentUsed" label="Curent de defect al buclei de punere la pământ" defaultValue={formData["defectLoopCurrentUsed"]} name="defectLoopCurrentUsed" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="ddrUsed" label="DDR/RCD" defaultValue={formData["ddrUsed"]} name="ddrUsed" fullWidth sx={{mb: 2}}/>
                                        <TextField required id="groundingElectrodeResistanceUsed" label="Rezistența electrodului de punere la pământ" defaultValue={formData["groundingElectrodeResistanceUsed"]} name="groundingElectrodeResistanceUsed" fullWidth sx={{mb: 2}}/>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}}>
                                            VERIFICĂRI SUPLIMENTARE
                                        </Typography>
                                        <List sx={{listStyle: 'numbered', p: 2}}>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea fucționării echipamentului'} suffix={'1'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței marcajelor pentru public'} suffix={'2'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței indicațiilor pentru utilizatori'} suffix={'3'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței elementelor necesare pe ecrane'} suffix={'4'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței elementelor necesare pe tipărituri'} suffix={'5'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea corectitudinii coordonatelor temporale afișate(data, ora)'} suffix={'6'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței sistemelor de asistență (voce, text, etc.)'} suffix={'7'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței etichetelor de inspecție'} suffix={'8'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea blocării fizice a accesului neautorizat'} suffix={'9'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea prezenței marcajelor de pericol, unde este cazul'} suffix={'10'}/>
                                            <ListItem sx={{display: "list-item"}}>
                                                <List sx={{listStyle: 'disc', p: 2}}>
                                                    <AdditionalChecksItem formData={formData} text={'Lipsa barierelor arhitectonice'} suffix={'11'}/>
                                                    <AdditionalChecksItem formData={formData} text={'Prezență mână curentă'} suffix={'12'}/>
                                                    <AdditionalChecksItem formData={formData} text={'Conformarea scărilor'} suffix={'13'}/>
                                                    <AdditionalChecksItem formData={formData} text={'Prezența suprafețelor antiderapante'} suffix={'14'}/>
                                                    <AdditionalChecksItem formData={formData} text={'Conformarea amplasării comenzilor'} suffix={'15'}/>
                                                    <AdditionalChecksItem formData={formData} text={'Prezența sistemelor de asistenta'} suffix={'16'}/>
                                                    <AdditionalChecksItem formData={formData} text={'Prezența sistemelor de asistență vocală'} suffix={'17'}/>
                                                </List>
                                            </ListItem>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea funcționării sistemelor de plată'} suffix={'18'}/>
                                            <AdditionalChecksItem formData={formData} text={'Verificarea funcționării aplicației mobile'} suffix={'19'}/>
                                        </List>
                                    </Box>
                                </>
                            }
                            {
                                activeStep === 4 &&
                                <>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3, mb: 2}} id="section-4">
                                            CONCLUZII
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography component="p" fontWeight={500}>
                                                    Recomandări
                                                </Typography>
                                                <FormControl fullWidth>
                                                    <TextField required id="recommendations" name="recommendations" defaultValue={formData["recommendations"]} fullWidth multiline minRows={5} maxRows={10}/>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography component="h5" variant="h5" sx={{fontWeight: '500', mt: 3}}>
                                            DECLARAŢIE
                                        </Typography>
                                        <Typography component="p" sx={{mb: 2}}>
                                            Eu/Noi sunt/suntem persoana/persoanele responsabilă/responsabile pentru inspecția și încercările instalației electrice (așa
                                            cum este indicat de semnăturile de mai jos) ale cărei detalii sunt descrise mai sus și care au fost realizate cu toată competența
                                            și atenția necesare în mod rezonabil, declar/declarăm prin prezenta că informațiile din acest raport, inclusiv observațiile și listele atașate, oferă o evaluare corectă a stării instalației electrice ținând seama de mărimea și limitările indicate în secțiunea
                                            „Mărime și limitări ale inspecției și încercărilor” a acestui raport.
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Typography component="p" fontWeight={500}>
                                                    INSPECŢIE ŞI ÎNCERCĂRI EFECTUATE DE:
                                                </Typography>
                                                <TextField required id="inspectorName" defaultValue={formData["inspectorName"]} label="Nume (cu majuscule)" name="inspectorName"  fullWidth sx={{my: 2}}/>
                                                <TextField required id="inspectorReportFor" defaultValue={formData["inspectorReportFor"]}  label="Pentru/În numele" name="inspectorReportFor" fullWidth sx={{mb: 2}}/>
                                                <TextField required id="roleOfInspector" defaultValue={formData["roleOfInspector"]}  label="Functia" name="roleOfInspector" fullWidth sx={{mb: 2}}/>
                                                <TextField disabled id="inspectorAddress" defaultValue={'DEKRA CERTIFICATION - Bulevardul Tudor Vladimirescu 22, Bucuresti 050883'}  label="Adresa" name="inspectorAddress" fullWidth sx={{mb: 2}}/>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="Data"
                                                        value={inspectorSignatureDate}
                                                        onChange={(newValue) => {
                                                            setInspectorSignatureDate(newValue);
                                                        }}
                                                        id="inspectorSignatureDate" name="inspectorSignatureDate" sx={{mb: 2}} fullWidth
                                                    />
                                                </LocalizationProvider>
                                                <Signature handleSave={(data) => setInspectorSignature(data)} initialSignature={inspectorSignature}/>
                                            </Grid>
                                            {
                                                userHasAnyRole([UserRole.SUPERVISOR, UserRole.SYSTEM_ADMIN]) &&
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Typography component="p" fontWeight={500}>
                                                        APROBAREA EMITERII RAPORTULUI DE:
                                                    </Typography>
                                                    <TextField required id="supervisorName" defaultValue={formData["supervisorName"]} label="Nume (cu majuscule)" name="supervisorName"  fullWidth sx={{my: 2 }}/>
                                                    <TextField required id="supervisorReportFor" defaultValue={formData["supervisorReportFor"]} label="Pentru/În numele" name="supervisorReportFor" fullWidth sx={{mb: 2 }}/>
                                                    <TextField required id="roleOfSupervisor" defaultValue={formData["roleOfSupervisor"]} label="Functia" name="roleOfSupervisor" fullWidth sx={{mb: 2 }}/>
                                                    <TextField disabled id="supervisorAddress" defaultValue={'DEKRA CERTIFICATION - Bulevardul Tudor Vladimirescu 22, Bucuresti 050883'} label="Adresa" name="supervisorAddress" fullWidth sx={{mb: 2 }}/>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            label="Data"
                                                            value={supervisorSignatureDate}
                                                            onChange={(newValue) => {
                                                                setSupervisorSignatureDate(newValue);
                                                            }}
                                                            id="supervisorSignatureDate" name="supervisorSignatureDate" sx={{mb: 2}} fullWidth
                                                        />
                                                    </LocalizationProvider>
                                                    <Signature handleSave={(data) => setSupervisorSignature(data)} initialSignature={supervisorSignature}/>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </>
                            }
                            <Paper sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                background: 'white',
                                position: 'fixed',
                                width: '100%',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                padding: '20px 0 20px 0',
                                overflowY: 'hidden',
                                zIndex: 5,
                                mt: 2
                            }}>
                                <Button
                                    color="inherit"
                                    sx={{ ml: 3 }}
                                    onClick={handleCancel}
                                    variant={'outlined'}
                                >
                                    Anulare
                                </Button>
                                {
                                    userHasRole(UserRole.SUPERVISOR) && !supervisionStarted &&
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleStartSupervision}
                                    >
                                        Start Supervizare
                                    </Button>
                                }
                                <Box>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        sx={{ mr: 4 }}
                                        onClick={handleBack}
                                    >
                                        Pasul anterior
                                    </Button>
                                    {
                                        submitButtonDisabled ?
                                            <ClickAwayListener onClickAway={closeSubmitButtonTooltip}>
                                                <Tooltip title={'Trebuie sa incepi supervizarea pentru a putea trece la urmatorul pas raportul'}
                                                         onClose={closeSubmitButtonTooltip}
                                                         open={submitButtonTooltip}
                                                         disableFocusListener
                                                         disableHoverListener
                                                         disableTouchListener
                                                         placement={'top'}
                                                >
                                                    <span onClick={openSubmitButtonTooltip}>
                                                        <Button sx={{ mr: 3 }} color="primary" variant="contained" disabled key={'next'}>
                                                            Pasul urmator
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            :
                                            <Button sx={{ mr: 3 }} color="primary" variant="contained" type="submit" key={'next'}>
                                                {getSubmitButtonText()}
                                            </Button>
                                    }
                                </Box>
                            </Paper>
                        </Container>
                    </>
            }
        </>
    );
}

export default DekraEEForm;
