import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React from "react";

function CircuitDetailsItem({index, formData}) {

    const NUMERIC_FIELD_REGEX = new RegExp("^[0-9-.]+$");

    return (
        <Box boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'} borderRadius={2} p={2} mb={2}>
            <ListItem sx={{display: "list-item", px: 0}}>
                <Grid container spacing={2}>
                    <Grid item xs={9} md={3} lg={3}>
                        <TextField required id={`circuitName${index}`} defaultValue={formData[`circuitName${index}`]} label="Nume/Referință" name={`circuitName${index}`} fullWidth/>
                    </Grid>
                    <Grid item xs={3} md={2} lg={2}>
                        <FormControl fullWidth>
                            <InputLabel id="type-select-label">Tip</InputLabel>
                            <Select required defaultValue={formData[`circuitType${index}`]} name={`circuitType${index}`} id={`circuitType${index}`} labelId="type-select-label" label="Tip">
                                <MenuItem value={"A"}>A</MenuItem>
                                <MenuItem value={"B"}>B</MenuItem>
                                <MenuItem value={"C"}>C</MenuItem>
                                <MenuItem value={"D"}>D</MenuItem>
                                <MenuItem value={"E"}>E</MenuItem>
                                <MenuItem value={"F"}>F</MenuItem>
                                <MenuItem value={"G"}>G</MenuItem>
                                <MenuItem value={"H"}>H</MenuItem>
                                <MenuItem value={"O"}>O</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField required id={`circuitMethod${index}`} label="Metoda de referință la instalare" defaultValue={formData[`circuitMethod${index}`]} name={`circuitMethod${index}`} fullWidth/>
                    </Grid>
                    <Grid item xs={3} md={2} lg={2}>
                        <TextField
                            required
                            id={`circuitSectionLN${index}`}
                            label="(mm) Secțiune L,N"
                            defaultValue={formData[`circuitSectionLN${index}`]} name={`circuitSectionLN${index}`}
                            onKeyDown={(event) => {
                                if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                    event.preventDefault();
                                }
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} md={2} lg={2}>
                        <TextField
                            required
                            id={`circuitSectionPE${index}`}
                            label="(mm) Secțiune PE"
                            defaultValue={formData[`circuitSectionPE${index}`]} name={`circuitSectionPE${index}`}
                            onKeyDown={(event) => {
                                if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                    event.preventDefault();
                                }
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <List>
                    <ListItem sx={{display: 'list-item', px: 0}}>
                        <Grid container spacing={2}>
                            <Grid item display={'flex'} alignItems={'center'} xs={12} md={3}>
                                <Typography component="p" sx={{fontWeight: 500}}>Disjunctor</Typography>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-select-label">Tip</InputLabel>
                                    <Select required defaultValue={formData[`circuitDisjunctorType${index}`]} name={`circuitDisjunctorType${index}`} id={`circuitDisjunctorType${index}`} labelId="type-select-label" label="Tip">
                                        <MenuItem value={"A"}>A</MenuItem>
                                        <MenuItem value={"B"}>B</MenuItem>
                                        <MenuItem value={"C"}>C</MenuItem>
                                        <MenuItem value={"D"}>D</MenuItem>
                                        <MenuItem value={"E"}>E</MenuItem>
                                        <MenuItem value={"F"}>F</MenuItem>
                                        <MenuItem value={"G"}>G</MenuItem>
                                        <MenuItem value={"H"}>H</MenuItem>
                                        <MenuItem value={"O"}>O</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    required
                                    id={`circuitDisjunctorNominalCurrent${index}`}
                                    label="(A) Curent nominal"
                                    defaultValue={formData[`circuitDisjunctorNominalCurrent${index}`]} name={`circuitDisjunctorNominalCurrent${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    required
                                    id={`circuitBreakingCurrent${index}`}
                                    label="(kA) Curent rupere"
                                    defaultValue={formData[`circuitBreakingCurrent${index}`]} name={`circuitBreakingCurrent${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem sx={{display: 'list-item', px: 0}}>
                        <Grid container spacing={2}>
                            <Grid item display={'flex'} alignItems={'center'} xs={12} md={3}>
                                <Typography component="p" sx={{fontWeight: 500}}>DDR/RCD</Typography>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <TextField
                                    required
                                    id={`circuitResidualCurrent${index}`}
                                    label="(mA) Curent rezidual In"
                                    defaultValue={formData[`circuitResidualCurrent${index}`]} name={`circuitResidualCurrent${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <TextField
                                    required
                                    id={`circuitOpTime${index}`}
                                    label="(ms) Timp de operare la In"
                                    defaultValue={formData[`circuitOpTime${index}`]} name={`circuitOpTime${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <TextField
                                    required
                                    id={`circuitOpTime5x${index}`}
                                    label="(ms) Timp de operare la 5xIn"
                                    defaultValue={formData[`circuitOpTime5x${index}`]} name={`circuitOpTime5x${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem sx={{display: 'list-item', px: 0}}>
                        <Grid container spacing={2}>
                            <Grid item display={'flex'} alignItems={'center'} xs={12} md={3}>
                                <Typography component="p" sx={{fontWeight: 500}}>Zs</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required
                                    id={`circuitMaxImpedance${index}`}
                                    label="(Ω) Impedanța max. buclă punere la pamant Zs"
                                    defaultValue={formData[`circuitMaxImpedance${index}`]} name={`circuitMaxImpedance${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem sx={{display: 'list-item', px: 0}}>
                        <Grid container spacing={2}>
                            <Grid item display={'flex'} alignItems={'center'} xs={12} md={3}>
                                <Typography component="p" sx={{fontWeight: 500}}>Rezistență izolație MΩ</Typography>
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField
                                    required
                                    id={`circuitLL${index}`}
                                    label="(MΩ) L-L"
                                    defaultValue={formData[`circuitLL${index}`]} name={`circuitLL${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField
                                    required
                                    id={`circuitLN${index}`}
                                    label="(MΩ) L-N"
                                    defaultValue={formData[`circuitLN${index}`]} name={`circuitLN${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField
                                    required
                                    id={`circuitLPE${index}`}
                                    label="(MΩ) L-PE"
                                    defaultValue={formData[`circuitLPE${index}`]} name={`circuitLPE${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextField
                                    required
                                    id={`circuitNPE${index}`}
                                    label="(MΩ) N-PE"
                                    defaultValue={formData[`circuitNPE${index}`]} name={`circuitNPE${index}`}
                                    onKeyDown={(event) => {
                                        if (!NUMERIC_FIELD_REGEX.test(event.key) && event.key !== 'Backspace') {
                                            event.preventDefault();
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </ListItem>
        </Box>
    );
}

export default CircuitDetailsItem;
