import {Box, Button, Dialog, DialogActions, DialogTitle, Slide} from "@mui/material";
import React from "react";
import {downloadForm} from "../../api/formApi";
import useErrorHandler from "../../hooks/error/useErrorHandler";
import LoadingBackdrop from "../LoadingBackdrop";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function FormActionsDialog({formId, openDialog, handleCloseDialog, handleOpenForm}) {
    const setError = useErrorHandler();
    const [downloading, setDownloading] = React.useState(false);

    const handleDownloadPdf = async () => {
        setDownloading(true);
        try {
            const response = await downloadForm(formId);
            const linkSource = `data:application/pdf;base64,${response.data}`;
            const downloadLink = document.createElement("a");
            const fileName = response.headers["content-disposition"];
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            handleCloseDialog()
        } catch (e) {
            setError("Eroare la descarcarea raportului", e);
        }
        setDownloading(false);
    }


    return (
        <Dialog open={openDialog} onClose={handleCloseDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="action-dialog"
                fullWidth
                maxWidth={"md"}
        >
            <LoadingBackdrop open={downloading}/>
            <DialogTitle>
                Actiuni disponibile pentru raportul: {formId}
            </DialogTitle>
            <DialogActions sx={{m: 2, justifyContent: "space-between"}}>
                <Button onClick={handleCloseDialog}>Anulare</Button>
                <Box justifyContent={'right'}>
                    <Button onClick={handleDownloadPdf} variant={"contained"} sx={{m: 1}}>Descarca PDF</Button>
                    <Button onClick={handleOpenForm} variant={"contained"} sx={{m: 1}}>Vizualizeaza in
                        aplicatie</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default FormActionsDialog;
