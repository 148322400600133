import {FormStatusDisplay, FormStatus} from "./FormStatus";

export const getFormattedStatus = (status) => {
    if (status === FormStatus.INSPECTION_STARTED) {
        return {
            statusLabel: FormStatusDisplay.INSPECTION_STARTED,
            statusColor: 'default'
        };
    }
    if (status === FormStatus.INSPECTION_ENDED) {
        return {
            statusLabel: FormStatusDisplay.INSPECTION_ENDED,
            statusColor: 'secondary'
        };
    }
    if (status === FormStatus.TO_REVIEW) {
        return {
            statusLabel: FormStatusDisplay.TO_REVIEW,
            statusColor: 'info'
        };
    }
    if (status === FormStatus.SUPERVISION_STARTED) {
        return {
            statusLabel: FormStatusDisplay.SUPERVISION_STARTED,
            statusColor: 'warning'
        };
    }
    if (status === FormStatus.SUPERVISION_ENDED) {
        return {
            statusLabel: FormStatusDisplay.SUPERVISION_ENDED,
            statusColor: 'primary'
        };
    }
    if (status === FormStatus.CHANGES_NEEDED) {
        return {
            statusLabel: FormStatusDisplay.CHANGES_NEEDED,
            statusColor: 'secondary'
        };
    }
    return {
        statusLabel: 'Unknown',
        statusColor: 'error'
    };
}
