import moment from "moment";

export const formatDateFromMoment = (date) => {
    if (!date) {
        return null
    }
    return moment(date).format('DD/MM/YYYY');
}

export const formatDateToMoment= (date) => {
    if (!date) {
        return moment();
    }
    return moment(date, "DD/MM/YYYY");
}

export const currentDate = () => {
    return moment()
}

export const addMonthsToDate = (date, months) => {
    if (!date || !months) {
        return moment();
    }
    return moment(date).add(months, 'months');
}
