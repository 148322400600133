import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import React from "react";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FormCancelDialog({openCancelDialog, handleCancelDialog, handleContinueForm}) {
    return(
        <Dialog
            open={openCancelDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleContinueForm}
            aria-describedby="cancel-dialog"
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle>{"Esti sigur ca vrei sa anulezi completarea formularului?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="cancel-dialog">
                    In cazul in care doriti anularea formularului, datele modificate/adaugate vor fi pierdute.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between", m: 2}}>
                <Button onClick={handleCancelDialog}>Anulare</Button>
                <Button onClick={handleContinueForm} variant={"contained"}>Continua formularul</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormCancelDialog;