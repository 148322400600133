import {Navigate} from "react-router-dom";
import React, {useEffect} from "react";
import useUser from "../hooks/user/useUser";
import UserRole from "../utils/UserRole";
import LoadingBackdrop from "./LoadingBackdrop";

function PathRedirect() {
    const {getUserRoles, userHasAnyRole} = useUser();

    const [basePath, setBasePath] = React.useState(undefined);

    useEffect(() => {
        const getBasePathForUser = () => {
            if (userHasAnyRole([UserRole.ADMIN, UserRole.SYSTEM_ADMIN])) {
                return "/admin";
            }
            return "/forms";
        }

        if (!!getUserRoles()) {
            setBasePath(getBasePathForUser());
        }
    }, [getUserRoles, userHasAnyRole]);

    return (
        <>
            {
                !!basePath ?
                    <Navigate replace to={basePath}/>
                    :
                    <LoadingBackdrop/>
            }
        </>
    );
}

export default PathRedirect;
