import axios from "./axios-client";
import {defaultPageSize, getAuthorizedRequestHeaders, serverUrl} from "./apiUtils";


export const reportFormSubmit = async (data) => {
    return axios.post(serverUrl + "/api/forms/submit", data, getAuthorizedRequestHeaders())
}

export const reportFormSign = async (formId, data) => {
    return axios.put(serverUrl + `/api/forms/supervision/sign/${formId}`, data, getAuthorizedRequestHeaders())
}

export const updateForm = async (formId, data) => {
    return axios.put(serverUrl + `/api/forms/update/${formId}`, data, getAuthorizedRequestHeaders())
}

export const solveFormChangesNeeded = async (formId, data) => {
    return axios.put(serverUrl + `/api/forms/update/solve/${formId}`, data, getAuthorizedRequestHeaders())
}

export const getAllCurrentUserForms = async (page = 0) => {
    const {data} = await axios.get(serverUrl + `/api/forms?page=${page}&size=${defaultPageSize}`, getAuthorizedRequestHeaders())
    return data
}

export const getAllFormsOpenForReview = async (page = 0) => {
    const {data} = await axios.get(serverUrl + `/api/forms/supervision/review?page=${page}&size=${defaultPageSize}`, getAuthorizedRequestHeaders())
    return data
}

export const getFormData = async (formId) => {
    const {data} = await axios.get(serverUrl + `/api/forms/${formId}`, getAuthorizedRequestHeaders())
    return data
}

export const downloadForm = async (formId) => {
    return await axios.get(serverUrl + `/api/pdf/${formId}`, getAuthorizedRequestHeaders())
}

export const startSupervision = async (formId) => {
    return await axios.put(serverUrl + `/api/forms/supervision/start/${formId}`, null, getAuthorizedRequestHeaders())
}

export const rejectSupervision = async (formId) => {
    return await axios.put(serverUrl + `/api/forms/supervision/reject/${formId}`, null, getAuthorizedRequestHeaders())
}

export const searchForms = async (keyword) => {
    const {data} = await axios.get(serverUrl + `/api/forms/search?keyword=${keyword}`, getAuthorizedRequestHeaders())
    return data
}

export const getFormStatus = async (formId) => {
    const {data} = await axios.get(serverUrl + `/api/forms/status/${formId}`, getAuthorizedRequestHeaders())
    return data
}
